import React, { Component } from "react";
import Particle from "../services/particles";

/* Modules */
import { Link } from "react-router-dom";
import Slider from "react-slick";
import $ from "jquery";
import Particles from "react-particles-js";
import { ToastContainer, toast } from "react-toastify";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import Pagination from "rc-pagination";
import TradeViewChart from "react-crypto-chart";
import { ClipLoader } from "react-spinners";
import styles from "../assets/css/animation.module.scss";
import AwesomeSlider from "react-awesome-slider";
import AwesomeSliderStyles from "react-awesome-slider/src/styles";

/* Components */
import Header from "../components/header";
import Footer from "../components/footer";
import ImageModal from "../components/imageModal";
import CurrencyModal from "../components/currencyModal";

/* Services */
import { Api, socket, getCurrency } from "../services/webservices";
import { base32 } from "../services/Decrypt";

/*  JSON    */
import _country from "../JSON/country.json";
// import currencyJson from "../JSON/currency.json"

import moment from "moment-timezone";
import { changeUTCTime } from "../components/convertTimeToUTC";
import ReactZenDeskChat from "@inlightmedia/react-zendesk-chat";

import renderHTML from "react-render-html";

import loadjs from "loadjs";
import "../index.css";

let currencyJson = [];
var product_images = [];
var parseString = require("xml2js").parseString;
class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      a: "",
      _id: "",
      token: "",
      loading: false,
      fiat: false,
      whole_data1: [],
      whole_data2: [],
      paymentMethod: [],
      paymentMethodS: [],
      currencyArray: [],
      country: "",
      bank: "",
      Amount: "",
      imgg: "",
      title: "",
      llink: "",
      pubDate: "",
      creator: "",
      currency: "",
      calCurrency: "USD",
      calAmount: "",
      BTCAmount: "",
      imgg2: "",
      title2: "",
      llink2: "",
      pubDate2: "",
      creator2: "",
      dataRssA: [],
      dataRssB: [],
      zopimElement: !!document.getElementsByClassName("zopim")[0] || '',
      // start sanjay
      _data: "",
      noData: "Searching...",
      // end sanjay
      currencyModal: false,
      calCurrencyModal: false,
      paymethods: false,
      page: "",
      pages: "",
      pages2: "",
      showImage: false,
      prodImg: [],
      pgval: 0,
      pgval2: 0,
      totalpg: null,
      totalpg1: null,
      currPg: null,
      currPg1: null,
      wholedata1load: false,
      wholedata2load: false,
    };
    this.setValue = this.setValue.bind(this);
    this.setCalValue = this.setCalValue.bind(this);
  }

  // Callback function to handle data received from the
  //child component

  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--popUpBW-color", "#fff");
    } else {
      document.body.style.setProperty("--popUpBW-color", "#3e5c85");
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };

  getText = async () => {
    let img;
    let title1;
    let link;
    let date;
    let creator;
    let dataA = [];
    let dataB = [];

    let img2;
    let title22;
    let link2;
    let date2;
    let creator2;

    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // let data = JSON.parse(xhttp.responseText)
        parseString(xhttp.responseText, function(err, result) {
          dataA = result.rss.channel[0].item;
          console.log("===========>", result.rss.channel[0].item);
          for (let i = 0; i < 4; i++) {
            console.log("datatzz", result.rss.channel[0].item[i].description);
            parseString(result.rss.channel[0].item[i].description, function(
              err1,
              result1
            ) {
              console.log("ddddddd====", result1.img.$.src);
              // console.log("datatzz",result1.p.img[0].$.src);
              dataB.push(result1.img.$.src);
              // img = result1.p.img[0].$.src;
            });
          }

          parseString(result.rss.channel[0].item[1].description, function(
            err1,
            result1
          ) {
            img2 = result1.img.$.src;
          });
        });
        this.setState({
          imgg: img,
          llink: link,
          title: title1,
          pubDate: date,
        });
        this.setState({ dataRssA: dataA, dataRssB: dataB });
        // this.setState({imgg2:img2,llink2:link2, title2:title22, pubDate2:date2})

        let parser = new DOMParser();

        let xmlDoc = parser.parseFromString(xhttp.responseText, "text/xml");

        //  console.log(xmlDoc.getElementsByTagName("")[0].textContent)
        //  var details = xmlDoc.getElementsByTagName("item");

        // for (var i = 0; i < details.length; i++) {
        //     if (details[i].childNodes) {
        //         for (var j = 0; j < details[i].childNodes.length; j++) {
        //             var detail = details[i].childNodes[j];
        //             if (detail.nodeType === 1)
        //                 console.log( "details node " + (i + 1) + ": " + detail.nodeName + "=" + detail.firstChild.nodeValue );
        //         }
        //     }
        // }
      }
    };
    xhttp.open("GET", "https://news.bitcoin.com/feed/", true);
    xhttp.send();
  };

  getText2 = () => {
    let img;
    let title1;
    let link;
    let date;
    let creator;

    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // let data = JSON.parse(xhttp.responseText)
        parseString(xhttp.responseText, function(err, result) {
          title1 = result.rss.channel[0].item[0].title[0];
          link = result.rss.channel[0].item[0].link[0];
          date = new Date(
            result.rss.channel[0].item[0].pubDate[0]
          ).toLocaleDateString();

          parseString(result.rss.channel[0].item[0].description, function(
            err1,
            result1
          ) {
            //  img = result.p.img[0].$.src;
          });
          //  parseString(result.rss.channel[0].item[1].description, function (err1, result1) {
          //    console.log(result1.p.img[0].$.src);
          //    img2 = result1.p.img[0].$.src;

          //  });
        });
        //  this.setState({imgg:img,llink:link, title:title1, pubDate:date})
        this.setState({
          imgg2: img,
          llink2: link,
          title2: title1,
          pubDate2: date,
        });

        let parser = new DOMParser();

        let xmlDoc = parser.parseFromString(xhttp.responseText, "text/xml");

        //  console.log(xmlDoc.getElementsByTagName("")[0].textContent)
        //  var details = xmlDoc.getElementsByTagName("item");

        // for (var i = 0; i < details.length; i++) {
        //     if (details[i].childNodes) {
        //         for (var j = 0; j < details[i].childNodes.length; j++) {
        //             var detail = details[i].childNodes[j];
        //             if (detail.nodeType === 1)
        //                 console.log( "details node " + (i + 1) + ": " + detail.nodeName + "=" + detail.firstChild.nodeValue );
        //         }
        //     }
        // }
      }
    };
    xhttp.open("GET", "https://www.coindesk.com/feed", true);
    xhttp.send();
  };

  Populate_country = () => {
    let __array = [];
    for (var i = 0; i < _country.length; i++) {
      __array.push(<option key={i}>{_country[i].name}</option>);
    }
    return __array;
  };
  onClick_currency = (e) => {
    e.preventDefault();

    var value = e.target.value;
    this.setState({ currency: value, calAmount: "", BTCAmount: "" });
    this.fetchAmount();
  };
  Populate_currency = () => {
    console.log("cureeeeee==========>" + JSON.stringify(currencyJson.fiat));
    let currencyArray = [];
    if (currencyJson.fiat) {
      let currArr = Object.keys(currencyJson.fiat);
      for (var i = 0; i < currArr.length; i++) {
        currencyArray.push(
          <option key={i} value={currArr[i]}>
            {currArr[i]}
          </option>
        );
      }
    }
    return currencyArray;
  };
  componentDidMount = async () => {
    //this.setState({ loading: true });
    currencyJson = await getCurrency();
    console.log("==============currency===========>", currencyJson);
    // loadjs("https://code.jquery.com/jquery-3.4.1.slim.min.js", function() {
    //   $(".circleIcon").mouseover(function() {
    //     var getThisIndex = $(this).index();
    //     $(this)
    //       .addClass("active")
    //       .siblings()
    //       .removeClass("active");
    //     $(".animationWalletBox .sectionHead")
    //       .eq(getThisIndex)
    //       .addClass("active")
    //       .siblings()
    //       .removeClass("active");
    //   });
    // });

    window.scrollTo(0, 0);

    this.paymentMethodList();
    var addr = localStorage.getItem("Address");
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    setTimeout(() => {
      if (addr != null) {
        this.setState({ _id: id, token: token, addr: addr }, () =>
          this.stateApis()
        );
      }
      //this.animationCall();
      //this.stabilishSocketConnection()
    }, 300);
    setTimeout(() => {
     // this.setState({ loading: true });

      Api("user/getUser", "GET", {}, this.state.token, this.state._id).then(
        (resp) => {
          try {
            // this.setState({ loading: false })
            if ((resp.status = 200)) {
              if (
                resp.data.responseCode == 409 ||
                resp.data.responseCode == 410 ||
                resp.data.responseCode == 411
              ) {
                localStorage.removeItem("data");
                localStorage.removeItem("isLoggedin");
                localStorage.removeItem("data1");
                localStorage.removeItem("token");
                this.props.history.push("/");
              } else if (resp.data.responseCode == 502) {
                this.props.history.push("NotFound");
              }
            } else {
            }
          } catch (ex) {}
        }
      );
    }, 100);

    this.loadBuySellData(1, 1);
    this.loadBanner();
    var searchUrl = this.props.history.location.search;

    var walletSectionHeight = $(".walletAnimateSection").outerHeight();
    walletSectionHeight = walletSectionHeight / 2;
    this.getText();
    this.getText2();
    this._loadData();

    $(window).scroll(function() {
      //console.log("shoeeaaaaaaaaaaaa   ")
      //alert("ddddd")
      var scroll = $(window).scrollTop();
      if (scroll >= 50) {
        $("header").addClass("header-bg");
        $(".fixedBox").addClass("fixdtop");
      } else {
        $("header").removeClass("header-bg");
        $(".fixedBox").removeClass("fixdtop");
      }
    });
  };
  async loadBanner() {
    return;
    await window.$(".bannerSlider").slick({
      dots: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }

  getTimeZonebyProfile() {
    setTimeout(() => {
      var parameter = {
        _id: this.state._id,
      };

      Api(
        "user/userProfile",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        this.setState({ loading: false });
        try {
          if ((resp.status = 200)) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              this.setState({ loading: false });
              localStorage.removeItem("data");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");
              this.props.history.push("/");
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else if (resp.data.responseCode == 200) {
              var _values = resp.data.result;

              if (_values.utc) {
                localStorage.setItem("utcTimeZone", resp.data.result.utc);
              }
            }
          } else {
            // toast_func("error", "Some problem occured please contact administrator")
          }
        } catch (ex) {
          // toast_func("error", "Some problem occured please contact administrator")
        }
      });
    }, 100);
  }

  stateApis = () => {
    if (this.state.token) this.getTimeZonebyProfile();
    Api(
      `user/deposits_save/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
    Api(
      `user/transferToAdminAccount/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
  };

  setMethod = (e, item) => {
    e.preventDefault();
    this.setState({
      bank: item.name,
      paymethods: false,
      method: item.name,
      paymentId: item._id,
    });
  };

  searchFilter = (value1, value2, e, action) => {
    e.preventDefault()
    // alert(value1+"===",e.target)
    let pageVal1 = this.state.pgval+1;
    let pageVal2 = this.state.pgval2+1;
    if (value1 == null && value2 == null) {
      this.setState({ whole_data1: [], whole_data2: [], pgval: 1, pgval2: 1 });
    }else{
      this.setState({pgval: this.state.pgval+1, pgval2:this.state.pgval2+1 });

    }
    
    this.setState({ noData: "Searching..." });
    if (this.state.bank != "") {
      var type = "buy-sell-";
      var method = type + this.state.bank.replace(/ /g, "-");
      this.props.history.push(`/bitcoins/${method}`);
    }
    var parameter1 = {
      type: "buy",
      payment_method: this.state.bank,
      amount: this.state.Amount,
      location:
        this.state.country == "Select Country" ? "" : this.state.country,
      currency_type: this.state.currency,
      limit: 10,
      page:  pageVal1,
      // page: value1,
    };
    var parameter2 = {
      type: "sell",
      payment_method: this.state.bank,
      amount: this.state.Amount,
      location:
        this.state.country == "Select Country" ? "" : this.state.country,
      currency_type: this.state.currency,
      limit: 10,
      page:  pageVal2,
    };

    if (value1 != null || action=="search") {
      this.setState({ loader1: true });
      console.log("=====>>>>>P1==", parameter1);
      Api(
        "trade/filter_trade",
        "POST",
        parameter1,
        this.state.token,
        this.state._id
      ).then((resp) => {
        this.setState({ loader1: false });
        // this.setState({pages: resp.data.result.pages})
        try {
          if (resp.status == 200) {
            if (resp.data.responseCode == 200) {
              this.allData(resp.data.result.docs);
              this.setState({
                totalpg: resp.data.result.pages,
                currPg: resp.data.result.page,
              });
              if (
                resp.data.responseCode == 409 ||
                resp.data.responseCode == 410 ||
                resp.data.responseCode == 411
              ) {
                localStorage.removeItem("data");
                localStorage.removeItem("isLoggedin");
                localStorage.removeItem("data1");
                localStorage.removeItem("token");

                this.props.history.push("/");
              } else if (resp.data.result.docs.length != 0) {
                // console.log("===========temp=======>>>>",tempAdslist)
                // resp.data.result.docs.map((item)=>{
                //   tempAdslist.push(item)
                // })
                // console.log("===========tempAdslist=======>>>>",tempAdslist);
                // this.setState(
                //   { whole_data1: tempAdslist, noData: "" ,populate_viewBuyMore: true},
                //   () => this.populate_viewBuyMore()
                // );
              } else {
                this.setState({ noData: "Sorry no offers found." });
                // toast_func("error", "No data matched for BUY as per your search")
              }
            } else if (resp.data.responseCode == 401) {
              this.setState({
                whole_data1: [],
                noData: "Sorry no offers found.",
              });
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else {
              // toast_func("error", resp.data.responseMessage)
            }
          } else {
            //toast_func("error", resp.data.responseMessage)
          }
        } catch (ex) {
          //toast_func("error", "Some problem occured please contact administrator")
        }
      });
    }
    if (value2 !== null || action=="search") {
      this.setState({ loader2: true });
      console.log("=====>>>>>P2==", parameter2);
      Api(
        "trade/filter_trade",
        "POST",
        parameter2,
        this.state.token,
        this.state._id
      ).then((resp) => {
        this.setState({ loader2: false });
        // this.setState({pages2: resp.data.result.pages})
        try {
          if (resp.status == 200) {
            if (resp.data.responseCode == 200) {
              if (resp.data.result.docs.length != 0) {
                this.allData2(resp.data.result.docs);
                this.setState({
                  totalpg1: resp.data.result.pages,
                  currPg1: resp.data.result.page,
                });
              } else {
                //toast_func("error", "No data matched for SELL as per your search")
              }
            } else if (resp.data.responseCode == 401) {
              this.setState({ whole_data2: [], noData: "Sorry no offers found." });
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else {
              //toast_func("error", resp.data.responseMessage)
            }
          } else {
            // toast_func("error", resp.data.responseMessage)
          }
        } catch (ex) {
          //toast_func("error", "Some problem occured please contact administrator")
        }
      });
    }
  };
  paymentMethodList() {
    Api(
      "trade/paymentMethodList",
      "GET",
      {},
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loading: false });

      try {
        if ((resp.status = 200)) {
          if (
            resp.data.responseCode == 409 ||
            resp.data.responseCode == 410 ||
            resp.data.responseCode == 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");

            this.props.history.push("/");
          } else if (resp.data.responseCode == 200) {
            this.setState({
              paymentMethod: resp.data.result,
              paymentMethodS: resp.data.result,
            });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else toast_func("error", resp.data.responseMessage);
        } else toast_func("error", resp.data.responseMessage);
      } catch (ex) {
        //toast_func("error", "Some problem occured please contact administrator")
      }
    });
  }
  BuySpecific = (e, Id, method) => {
    e.preventDefault();
    var id = e.target.id;
    var encryptData = base32.encode(Id);
    method = method.replace(/ /g, "-");

    this.props.history.push(
      window.open(`/buy-bitcoin-instantly/${method}/${encryptData}`, "_blank")
    );
  };

  SellSpecific = (e, id, method) => {
    e.preventDefault();
    // var userId = e.target.userId
    // alert(userId)
    var id1 = id;
    // var userId = this.state.whole_data2[id]["user_id"]
    var encryptData = base32.encode(id1);
    method = method.replace(/ /g, "-");
    this.props.history.push(
      window.open(`/sell-bitcoins-instantly/${method}/${encryptData}`, "_blank")
    );
  };

  allData = (arr) => {
    let tempArr = this.state.whole_data1;
    console.log("tempArrtempArr======>", tempArr);

    //this.setState({ whole_data1: [] });
    for (let i = 0; i < arr.length; i++) {
      var parameter = {
        userId: arr[i]["user_id"],
      };
      Api(
        `feedback/feedbackScore`,
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            if (resp.data.responseMessage == "No feedback found") {
              arr[i]["nega"] = 0;
              arr[i]["posi"] = 0;
              tempArr.push(arr[i]);
              this.setState({ whole_data1: tempArr });
            } else {
              arr[i]["nega"] = resp.data.feedbackScore.negative;
              arr[i]["posi"] = resp.data.feedbackScore.positive;
              tempArr.push(arr[i]);
              this.setState({ whole_data1: tempArr });
            }
          }
        }
      });
    }
  };

  allData2 = (arr) => {
    // this.setState({ whole_data2: [] });
    let tempArr = this.state.whole_data2;
    console.log("==================alldata2", tempArr);
    for (let i = 0; i < arr.length; i++) {
      var parameter = {
        userId: arr[i]["user_id"],
      };
      Api(
        `feedback/feedbackScore`,
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            if (resp.data.responseMessage == "No feedback found") {
              arr[i]["nega"] = 0;
              arr[i]["posi"] = 0;
              tempArr.push(arr[i]);
              this.setState({ whole_data2: tempArr });
            } else {
              arr[i]["nega"] = resp.data.feedbackScore.negative;
              arr[i]["posi"] = resp.data.feedbackScore.positive;
              tempArr.push(arr[i]);
              this.setState({ whole_data2: tempArr });
            }
          }
        }
      });
    }
  };

  loadBuySellData = (value1, value2) => {
    this.setState({ pgval: this.state.pgval + value1,pgval2: this.state.pgval2 + value1 });
    var parameter1 = {
      type: "buy",
      limit: 10,
      page: value1,
    };
    var parameter2 = {
      type: "sell",
      limit: 10,
      page: value2,
    };

    //this.setState({ loader1: true });
    Api(
      "trade/filter_trade",
      "POST",
      parameter1,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loader1: false });

      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              localStorage.removeItem("data");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");

              this.props.history.push("/");
            } else if (resp.data.result.docs.length != 0) {
              // this.setState({
              //   whole_data1: resp.data.result["docs"],
              //   whole_data1Total: resp.data.result["total"],
              // });
              this.allData(resp.data.result["docs"]);
              this.setState({
                totalpg: resp.data.result.pages,
                currPg: resp.data.result.page,
              });
            } else {
              // toast_func("error", "No data matched as per your search")
            }
          } else if (resp.data.responseCode == 401) {
            this.setState({ whole_data1: [] });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            //toast_func("error", resp.data.responseMessage)
          }
        } else {
          // toast_func("error", resp.data.responseMessage)
        }
      } catch (ex) {
        //toast_func("error", "Some problem occured please contact administrator")
      }
    });
    this.setState({ loader2: true });
    Api(
      "trade/filter_trade",
      "POST",
      parameter2,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loader2: false });

      try {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              localStorage.removeItem("data");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");

              this.props.history.push("/");
            } else if (resp.data.result.docs.length != 0) {
              this.setState({
                // whole_data2: resp.data.result.docs,
                result2: true,
                whole_data2Total: resp.data.result["total"],
              });
              this.setState({
                totalpg1: resp.data.result.pages,
                currPg1: resp.data.result.page,
              });

              this.allData2(resp.data.result.docs);
            } else {
              //toast_func("error", "No data matched as per your search")
            }
          } else if (resp.data.responseCode == 401) {
            this.setState({ whole_data2: [] });
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else {
            //toast_func("error", resp.data.responseMessage)
          }
        } else {
          // toast_func("error", resp.data.responseMessage)
        }
      } catch (ex) {
        //toast_func("error", "Some problem occured please contact administrator")
      }
    });
  };
  async animationCall() {
    var walletSectionHeight = $(".walletAnimateSection").outerHeight();
    walletSectionHeight = walletSectionHeight / 2;

    if (window == undefined) {
      return;
    }
    this.headerbg();
    // window.$(window).scroll(function () {
    //   this.headerbg();
    //   if (window.$(window).scrollTop() > window.$(".walletAnimateSection").offset().top - walletSectionHeight) {
    //     window.$(".walletAnimateSection").removeClass("circleBeforeAnimate");
    //   }
    // });
    $(window).scroll(function() {
      //const walletAnimation = await $(".walletAnimateSection")
      if ($(".walletAnimateSection").offset() == undefined) {
        return;
      }
      if (
        $(window).scrollTop() >
        $(".walletAnimateSection").offset().top - walletSectionHeight
      ) {
        $(".walletAnimateSection").removeClass("circleBeforeAnimate");
      }
    });

    await this.loadJquery();
  }
  onClick_country = (e) => {
    e.preventDefault();
    var value = e.target.value;
    if (value == "Select Country") {
      this.setState({ country: "", countryError: "" });
    } else {
      this.setState({ country: value, countryError: "" });
    }
  };

  headerbg() {
    var scroll = $(window).scrollTop();
    if (scroll >= 50) {
      $("header").addClass("header-bg");
    } else {
      $("header").removeClass("header-bg");
    }
  }

  loadJquery = async () => {
    let particle = new Promise((resolve, reject) => {
      try {
        resolve(
          window.particlesJS("particles-js", {
            particles: {
              number: {
                value: 80,
                density: {
                  enable: true,
                  value_area: 1500,
                },
              },

              color: {
                value: "#000",
              },
              shape: {
                type: "circle",
                stroke: {
                  width: 0,
                  color: "#000",
                },
                polygon: {
                  nb_sides: 5,
                },
                image: {
                  src: "img/github.svg",
                  width: 100,
                  height: 100,
                },
              },
              opacity: {
                value: 0.5,
                random: false,
                anim: {
                  enable: false,
                  speed: 1,
                  opacity_min: 0.1,
                  sync: false,
                },
              },
              size: {
                value: 5,
                random: true,
                anim: {
                  enable: false,
                  speed: 40,
                  size_min: 0.1,
                  sync: false,
                },
              },
              line_linked: {
                enable: true,
                distance: 150,
                color: "#000",
                opacity: 0.4,
                width: 1,
              },
              move: {
                enable: true,
                speed: 6,
                direction: "none",
                random: false,
                straight: false,
                out_mode: "out",
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 1200,
                },
              },
            },
            interactivity: {
              detect_on: "canvas",
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
                onclick: {
                  enable: true,
                  mode: "push",
                },
                resize: true,
              },
              modes: {
                grab: {
                  distance: 400,
                  line_linked: {
                    opacity: 1,
                  },
                },
                bubble: {
                  distance: 400,
                  size: 40,
                  duration: 2,
                  opacity: 8,
                  speed: 3,
                },
                repulse: {
                  distance: 200,
                },
                push: {
                  particles_nb: 4,
                },
                remove: {
                  particles_nb: 2,
                },
              },
            },
            retina_detect: true,
            config_demo: {
              hide_card: false,
              background_color: "#000",
              background_image: "",
              background_position: "50% 50%",
              background_repeat: "no-repeat",
              background_size: "cover",
            },
          })
        );
      } catch (ex) {}
    });

    let promise = await particle;

    if (promise) {
      return promise;
    } else {
    }
  };

  fetchAmount(amount) {
    console.log("okay ----->", this.state.currency);
    var parameter = {
      localCurrency: this.state.calCurrency,
      btc: this.state.BTCAmount,
    };
    this.setState({ loader1: true });
    Api(
      "user/priceEquation",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loader1: false });
      console.log("fatchAmount==>>", resp);
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          var num = Number(resp.data.result["price"]);
          var n = num.toFixed(2);
          var n1 = n
            .split(".")[0]
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var n2 = n.split(".")[1];
          var price = n1 + "." + n2;
          //alert(JSON.stringify(resp.data.result["price"]))
          //this.setState({Amount})
          console.log("working fine--->", price);
          this.setState({ calAmount: n, priceWithoutRefine: n });
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
        }
      }
    });
  }
  fetchBTC() {
    console.log("okay--------------A>", this.state.calCurrency);
    var parameter = {
      localCurrency: this.state.calCurrency,
      margin: "",
    };
    this.setState({ loader2: true });
    Api(
      "user/priceEquationWithMargin",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      this.setState({ loader2: false });
      console.log("fatchBTC==>>", resp);
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          console.log("rrrrrrrr", resp.data);
          var priceNow = resp.data.result.price;
          console.log("rrrrrrr price now===>", priceNow);
          console.log("rrrrrrr amount entered===>", this.state.price);
          var btc = this.state.calAmount / priceNow;
          console.log("rrrrrrr price now===>", btc);
          this.setState({ BTCAmount: btc.toFixed(8) });
        } else if (resp.data.responseCode == 502) {
          this.props.history.push("NotFound");
          this.setState({ loader2: false });
        }
      }
    });
  }

  callView() {
    return (
      <section className="commonSectionStyle bannerSliderSection">
        <div className="container1440">
          <div className="bannerSlider">
            <div className="slideimgbox">
              <div className="slideimg">
                <img
                  src={require("../assets/images/Folder 291.png")}
                  alt="Image"
                />
              </div>
              {/* <div className="slidetext">
                <h3>Gift Cards</h3>
                <h6>Instant - Private</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard.
               </p>
              </div> */}
            </div>
            <div className="slideimgbox">
              <div className="slideimg">
                <img
                  src={require("../assets/images/Folder 289.png")}
                  alt="Image"
                />
              </div>
              {/* <div className="slidetext">
                <h3>Gift Cards</h3>
                <h6>Instant - Private</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard.
               </p>
              </div> */}
            </div>
            <div className="slideimgbox">
              <div className="slideimg">
                <img
                  src={require("../assets/images/Folder 293.png")}
                  alt="Image"
                />
              </div>
              {/* <div className="slidetext">
                <h3>Gift Cards</h3>
                <h6>Instant - Private</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard.
               </p>
              </div> */}
            </div>
            <div className="slideimgbox">
              <div className="slideimg">
                <img
                  src={require("../assets/images/Folder 288.png")}
                  alt="Image"
                />
              </div>
              {/* <div className="slidetext">
                <h3>Gift Cards</h3>
                <h6>Instant - Private</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard.
               </p>
              </div> */}
            </div>
            {/* <div className="slideimgbox">
              <div className="slideimg">
                <img
                  src={require("../assets/images/news2.png")}
                  alt="Image"
                />
              </div>
            </div>
            */}
            {/* <div className="slideimgbox">
              <div className="slideimg">
                <img
                  src={require("../assets/images/news2.png")}
                  alt="Image"
                />
              </div>
            </div>  */}
          </div>
          {/* <!-- innovative_companies_slider End --> */}
        </div>
      </section>
    );
  }
  // handleOnChange = (e) => {
  //   e.preventDefault();
  //   let type = e.target.name
  //   let value = e.target.value

  //   if (type == 'Amount') {

  //     if (!isNaN(value) && (value !== " ")) {
  //       this.setState({ [type]: value })
  //     }
  //   }
  // }
  selecttab = (tabval) => {
    localStorage.setItem("tab", tabval);
    this.setState({ tab: tabval });
    //  this.getInvoiceList()
  };

  handleOnChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
    if (name == "BTCAmount") {
      var test = value.split("");
      if (test[test.length - 1] !== " ") {
        if (!isNaN(value) || value == ".") {
          var value2 = value.split("");
          if (value2[0] == ".") {
            this.setState({
              [name]: ".",
              BTCAmountError: "* Please enter valid BTC amount",
            });
          }
          // else if(value2[0] == 0)
          // {
          //   this.setState({ [name]: 0, BTCAmountError: "* Please enter BTC amount more then zero" })
          // }
          else {
            if (value[0] == "-") {
              value = value.substring(1, value.length);
              this.setState({ [name]: value }, () => this.fetchAmount());
              //  this.fetchAmount(value);
              // this.setState({BTCAmountError: ""}, () => this.fetchAmount())
            } else {
              this.setState({ [name]: value }, () => this.fetchAmount());
              // this.fetchAmount(value)
            }
            // this.setState({BTCAmountError: ""}, () => this.fetchAmount())
          }
        }
      }
    } else if (name == "calAmount") {
      var test = value.split("");
      if (test[test.length - 1] !== " ") {
        if (!isNaN(value)) {
          // console.log("rrrrrrrr im on change from amount")
          if (value[0] == "-") {
            value = value.substring(1, value.length);
            this.setState(
              { calAmount: value, priceWithoutRefine: value, amountError: "" },
              () => this.fetchBTC()
            );
            // this.fetchBTC();
          } else {
            this.setState(
              { calAmount: value, priceWithoutRefine: value, amountError: "" },
              () => this.fetchBTC()
            );
          }
          // this.fetchBTC();
          // console.log("rrrrrrrr amount after setState", this.state.price);
        }
      }
    } else if (name == "calCurrency") {
      this.setState({ [name]: value }, () => this.fetchAmount());
    } else if (name == "bank") {
      // console.log("Ritik===>",value)
      // {this.state.paymentMethod.map((item,index)=>{
      //   if(item.name.Find(value)){
      //     console.log("Ritik===>",item.name)
      //   }
      // })}
      var array = this.state.paymentMethod;

      if (value == "") {
        this.setState({ paymentMethodS: array });
      }
      let filterVal = [];
      function search(nameKey, myArray) {
        console.log("Ritik===>", nameKey);
        for (var i = 0; i < myArray.length; i++) {
          if (myArray[i].name.toLowerCase().match(nameKey.toLowerCase())) {
            // return myArray[i];
            console.log("Ritik===>", myArray[i].name);
            filterVal.push(myArray[i]);
          }
        }
      }
      this.setState({ paymentMethodS: filterVal });

      var resultObject = search(value, array);
    }
  };

  onClick_paymentBank = (e) => {
    e.preventDefault();
    var value = e.target.value;
    if (value == "All Online offers") {
      this.setState({ bank: "", bankError: "" });
    } else {
      this.setState({ bank: value, bankError: "" });
    }
  };
  Populate_paymentMethods = () => {
    let __array = [];
    for (var i = 0; i < this.state.paymentMethod.length; i++) {
      __array.push(<option key={i}>{this.state.paymentMethod[i].name}</option>);
    }
    return __array;
  };
  viewDetail = (e, userId) => {
    e.preventDefault();
    var id = e.target.id;

    var encryptData = base32.encode(userId);
    this.props.history.push(`/user-details/${encryptData}`);
  };
  populate_viewBuy = () => {
    let table = [];

    // Outer loop to create parent

    if (this.state.loader1 == true) {
      return (
        <tr>
          <td colSpan="6" align="center">
            {" "}
          </td>
        </tr>
      );
    } else if (this.state.whole_data1.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            {" "}
            <label className="font20 rColor text-center ">
              {this.state.noData}
            </label>
          </td>
        </tr>
      );
    }

    for (
      let i = 0;
      i <
      (this.state.whole_data1.length < 5 ? this.state.whole_data1.length : 5);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 6; j++) {
        if (j == 0) {
          children.push(
            <td key={j}>
              <div className="usr_st">
                <a
                  className="anchor-styling"
                  id={`${this.state.whole_data1[i]["user_id"]}`}
                  onClick={(e) =>
                    this.viewDetail(e, this.state.whole_data1[i]["user_id"])
                  }
                >{`${this.state.whole_data1[i]["user_name"]}`}</a>
                {this.state.whole_data1[i]["kyc_status"] == "APPROVED" && (
                  <img
                    src={require("../assets/images/completed.png")}
                    width={"30px"}
                    height={"30px"}
                  />
                )}
                <p>
                  <i
                    style={{ color: this.state.whole_data1[i]["color"] }}
                    className="dot fa fa-circle mr5"
                  />
                  {`${this.state.whole_data1[i]["userStatus"]}`}{" "}
                  <span>
                    <a
                      href={`/feedback/${base32.encode(
                        this.state.whole_data1[i]["user_id"]
                      )}`}
                    >
                      {" "}
                      (+
                      {`${this.state.whole_data1[i]["posi"]}`}
                      {" / "}
                      {" -"}
                      {`${this.state.whole_data1[i]["nega"]}`})
                    </a>
                  </span>
                </p>
              </div>
            </td>
          );
        } else if (j == 1) {
          children.push(
            <td key={j}>
              <a className="anchor-styling">{`${
                this.state.whole_data1[i]["payment_method"]
              }`}</a>
              <p className="mb-0 tags-pay">
                {this.populateTagBuy(i)}
                {/* <span className="badge">Primary</span>
            <span className="badge">Primary</span> */}
              </p>
            </td>
          );
        } else if (j == 2) {
          if (this.state.whole_data1[i]["price_equation"] !== undefined) {
            var num = Number(this.state.whole_data1[i]["price_equation"]);
            var n = num.toFixed(2);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td key={j}>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.whole_data1[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 3) {
          children.push(
            <td key={j}>{`${this.state.whole_data1[i]["min_transaction_limit"] +
              "-" +
              this.state.whole_data1[i]["max_transaction_limit"] +
              " " +
              this.state.whole_data1[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          let num = Number(this.state.whole_data1[i]["toPay"]);
          let num1 = num.toFixed(2);
          this.state.whole_data1[i]["toPay"]
            ? children.push(
                <td key={j} style={{ width: "100px" }}>
                  {"$" + `${num1}`}
                </td>
              )
            : children.push(<td style={{ width: "100px" }}>$0</td>);
        } else if (j === 5) {
          if (this.state.whole_data1[i].hasOwnProperty("product_images")) {
            if (this.state.whole_data1[i]["product_images"].length) {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img
                    src={
                      this.state.whole_data1[i]["product_images"][0]["imageUrl"]
                    }
                    onClick={(e) =>
                      this.setState({
                        showImage: true,
                        prodImg: this.state.whole_data1[i]["product_images"],
                      })
                    }
                  />
                  <span className="imagecount">
                    {this.state.whole_data1[i]["product_images"].length}
                  </span>
                </td>
              );
            } else {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img src={require("../assets/newImages/imgAlbum.png")} />
                  <span className="imagecount">0</span>
                </td>
              );
            }
          } else {
            children.push(
              <td key={j} className="Width110 text-center pRelative">
                <img src={require("../assets/newImages/imgAlbum.png")} />
                <span className="imagecount">0</span>
              </td>
            );
          }
        } else if (j === 6) {
          children.push(
            <td key={j} className="Width110 text-center">
              <a
                id={`${this.state.whole_data1[i]["_id"]}`}
                onClick={(e) =>
                  this.BuySpecific(
                    e,
                    this.state.whole_data1[i]["_id"],
                    this.state.whole_data1[i]["payment_method"]
                  )
                }
                className="btn btnGray minhyt minWidth80"
              >
                Buy
              </a>
            </td>
          );
        }
      }
      //Create the parent and add the children
      table.push(<tr key={i}>{children}</tr>);
    }
    return table;
  };

  populate_viewBuyMore = () => {
    console.log("========length", this.state.whole_data1.length);
    let table = [];
    //alert(this.state.whole_data1.length)
    // Outer loop to create parent
    if (this.state.loader1 == true) {
      return (
        <td colSpan="6" align="center">
          <span>
            <img
              src={require("../assets/ajax-loader.gif")}
              style={{ height: "25px", width: "25px" }}
              alt=""
            />
          </span>
        </td>
      );
    } else if (this.state.whole_data1.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            <label className="font20 rColor text-center ">
              {this.state.noData}
            </label>
          </td>
        </tr>
      );
    }
    for (let i = 0; i < this.state.whole_data1.length; i++) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 6; j++) {
        if (j == 0) {
          children.push(
            <td style={{}}>
              <div className="usr_st">
                <a
                  className="anchor-styling"
                  id={`${this.state.whole_data1[i]["user_id"]}`}
                  onClick={(e) =>
                    this.viewDetail(e, this.state.whole_data1[i]["user_id"])
                  }
                >{`${this.state.whole_data1[i]["user_name"]}`}</a>
                {this.state.whole_data1[i]["kyc_status"] == "APPROVED" && (
                  <img
                    src={require("../assets/images/completed.png")}
                    width={"30px"}
                    height={"30px"}
                  />
                )}
                <p>
                  <i
                    style={{ color: this.state.whole_data1[i]["color"] }}
                    className="dot fa fa-circle mr5"
                  />
                  {`${this.state.whole_data1[i]["userStatus"]}`}{" "}
                  <span>
                    <a
                      href={`/feedback/${base32.encode(
                        this.state.whole_data1[i]["user_id"]
                      )}`}
                    >
                      {" "}
                      (+
                      {`${this.state.whole_data1[i]["posi"]}`}
                      {" / "}
                      {" -"}
                      {`${this.state.whole_data1[i]["nega"]}`})
                    </a>
                  </span>
                </p>
              </div>
            </td>
          );
        } else if (j == 1) {
          children.push(
            <td>
              <a className="anchor-styling">{`${
                this.state.whole_data1[i]["payment_method"]
              }`}</a>
              <p className="mb-0 tags-pay">
                {this.populateTagBuy(i)}
                {/* <span className="badge">Primary</span>
            <span className="badge">Primary</span> */}
              </p>
            </td>
          );
        } else if (j == 2) {
          if (this.state.whole_data1[i]["price_equation"] !== undefined) {
            var num = Number(this.state.whole_data1[i]["price_equation"]);
            var n = num.toFixed(2);
            console.log("======NN", n);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.whole_data1[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 3) {
          children.push(
            <td>{`${this.state.whole_data1[i]["min_transaction_limit"] +
              "-" +
              this.state.whole_data1[i]["max_transaction_limit"] +
              " " +
              this.state.whole_data1[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          var num = Number(this.state.whole_data1[i]["toPay"]);
          var num1 = num.toFixed(2);
          this.state.whole_data1[i]["toPay"]
            ? children.push(
                <td style={{ width: "100px" }}>{"$" + `${num1}`}</td>
              )
            : children.push(<td style={{ width: "100px" }}>$0</td>);
        } else if (j === 5) {
          if (this.state.whole_data1[i].hasOwnProperty("product_images")) {
            if (this.state.whole_data1[i]["product_images"].length) {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img
                    src={
                      this.state.whole_data1[i]["product_images"][0]["imageUrl"]
                    }
                    onClick={(e) =>
                      this.setState({
                        showImage: true,
                        prodImg: this.state.whole_data1[i]["product_images"],
                      })
                    }
                  />
                  <span className="imagecount">
                    {this.state.whole_data1[i]["product_images"].length}
                  </span>
                </td>
              );
            } else {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img src={require("../assets/newImages/imgAlbum.png")} />
                  <span className="imagecount">0</span>
                </td>
              );
            }
          } else {
            children.push(
              <td key={j} className="Width110 text-center pRelative">
                <img src={require("../assets/newImages/imgAlbum.png")} />
                <span className="imagecount">0</span>
              </td>
            );
          }
        } else if (j === 6) {
          children.push(
            <td className="Width110 text-center">
              <a
                id={`${this.state.whole_data1[i]["_id"]}`}
                name={`${this.state.whole_data1[i]["user_id"]}`}
                onClick={(e) =>
                  this.BuySpecific(
                    e,
                    this.state.whole_data1[i]["_id"],
                    this.state.whole_data1[i]["payment_method"]
                  )
                }
                className="btn btnGray minhyt minWidth80"
              >
                Buy
              </a>
            </td>
          );
        }
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };
  populateTagSell = (i) => {
    let a = [];
    for (var k = 0; k < this.state.whole_data2[i]["add_tags"].length; k++) {
      a.push(
        <span className="badge" key={k}>
          {this.state.whole_data2[i]["add_tags"][k]}
        </span>
      );
    }
    return a;
  };
  populateTagBuy = (i) => {
    let a = [];
    for (var k = 0; k < this.state.whole_data1[i]["add_tags"].length; k++) {
      a.push(
        <span className="badge" key={k}>
          {this.state.whole_data1[i]["add_tags"][k]}
        </span>
      );
    }
    return a;
  };
  populate_viewSell = () => {
    let table = [];
    // Outer loop to create parent

    if (this.state.loader2 == true) {
      return (
        <tr>
          <td colSpan="6" align="center">
            <span>
              <img
                src={require("../assets/ajax-loader.gif")}
                style={{ height: "25px", width: "25px" }}
                alt=""
              />
            </span>
          </td>
        </tr>
      );
    } else if (this.state.whole_data2.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            {" "}
            <label className="font20 text-center rColor">
              {this.state.noData}
            </label>
          </td>
        </tr>
      );
    }

    for (
      let i = 0;
      i <
      (this.state.whole_data2.length < 5 ? this.state.whole_data2.length : 5);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 6; j++) {
        if (j == 0) {
          children.push(
            <td key={j}>
              <div className="usr_st">
                <a
                  className="anchor-styling"
                  id={`${this.state.whole_data2[i]["user_id"]}`}
                  onClick={(e) =>
                    this.viewDetail(e, this.state.whole_data2[i]["user_id"])
                  }
                >{`${this.state.whole_data2[i]["user_name"]}`}</a>
                {this.state.whole_data2[i]["kyc_status"] == "APPROVED" && (
                  <img
                    src={require("../assets/images/completed.png")}
                    width={"30px"}
                    height={"30px"}
                  />
                )}
                <p>
                  <i
                    style={{ color: this.state.whole_data2[i]["color"] }}
                    className="dot fa fa-circle mr5"
                  />
                  {`${this.state.whole_data2[i]["userStatus"]}`}{" "}
                  <span>
                    <a
                      href={`/feedback/${base32.encode(
                        this.state.whole_data2[i]["user_id"]
                      )}`}
                    >
                      {" "}
                      (+
                      {`${this.state.whole_data2[i]["posi"]}`}
                      {" / "}
                      {" -"}
                      {`${this.state.whole_data2[i]["nega"]}`})
                    </a>
                  </span>
                </p>
              </div>
            </td>
          );
        } else if (j == 1) {
          children.push(
            <td key={j}>
              <a className="anchor-styling">{`${
                this.state.whole_data2[i]["payment_method"]
              }`}</a>
              <p className="mb-0 tags-pay">
                {this.populateTagSell(i)}
                {/* <span className="badge">Primary</span>
            <span className="badge">Primary</span> */}
              </p>
            </td>
          );
        } else if (j == 2) {
          if (this.state.whole_data2[i]["price_equation"] !== undefined) {
            var num = Number(this.state.whole_data2[i]["price_equation"]);
            var n = num.toFixed(2);

            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td key={j}>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.whole_data2[i]["currency_type"]}`}</td>
            );
          } else children.push(<td>0</td>);
        } else if (j == 3) {
          children.push(
            <td key={j}>{`${this.state.whole_data2[i]["min_transaction_limit"] +
              "-" +
              this.state.whole_data2[i]["max_transaction_limit"] +
              " " +
              this.state.whole_data2[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          let num = Number(this.state.whole_data2[i]["toPay"]);
          let num1 = num.toFixed(2);
          this.state.whole_data2[i]["toPay"]
            ? children.push(
                <td key={j} style={{ width: "100px" }}>
                  {"$" + `${num1}`}
                </td>
              )
            : children.push(
                <td key={j} style={{ width: "100px" }}>
                  $0
                </td>
              );
        } else if (j == 5) {
          if (this.state.whole_data2[i].hasOwnProperty("product_images")) {
            if (this.state.whole_data2[i]["product_images"].length) {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img
                    src={
                      this.state.whole_data2[i]["product_images"][0]["imageUrl"]
                    }
                    onClick={(e) =>
                      this.setState({
                        showImage: true,
                        prodImg: this.state.whole_data2[i]["product_images"],
                      })
                    }
                  />
                  <span className="imagecount">
                    {this.state.whole_data2[i]["product_images"].length}
                  </span>
                </td>
              );
            } else {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img src={require("../assets/newImages/imgAlbum.png")} />
                  <span className="imagecount">0</span>
                </td>
              );
            }
          } else {
            children.push(
              <td key={j} className="Width110 text-center pRelative">
                <img src={require("../assets/newImages/imgAlbum.png")} />
                <span className="imagecount">0</span>
              </td>
            );
          }
        } else if (j == 6) {
          children.push(
            <td key={j} className="Width110 text-center">
              <a
                id={i}
                onClick={(e) =>
                  this.SellSpecific(
                    e,
                    this.state.whole_data2[i]["payment_method"]
                  )
                }
                className="btn btnGray minhyt minWidth80"
              >
                Sell
              </a>
            </td>
          );
        }
      }
      //Create the parent and add the children
      table.push(<tr key={i}>{children}</tr>);
    }
    return table;
  };
  populate_viewSellMore = () => {
    let table = [];
    // Outer loop to create parent
    if (this.state.loader2 == true) {
      return (
        <td colSpan="6" align="center">
          <span>
            <img
              src={require("../assets/ajax-loader.gif")}
              style={{ height: "25px", width: "25px" }}
              alt=""
            />
          </span>
        </td>
      );
    } else if (this.state.whole_data2.length == 0) {
      return (
        <tr>
          <td colSpan="6" align="center">
            {" "}
            <label className="font20 rColor text-center">
              {this.state.noData}
            </label>
          </td>
        </tr>
      );
    }
    for (
      let i = 0;
      i <
      (this.state.whole_data2.length < 10 ? this.state.whole_data2.length : 10);
      i++
    ) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j <= 6; j++) {
        if (j == 0) {
          children.push(
            <td key={j}>
              <div className="usr_st">
                <a
                  className="anchor-styling"
                  id={`${this.state.whole_data2[i]["user_id"]}`}
                  onClick={(e) =>
                    this.viewDetail(e, this.state.whole_data2[i]["user_id"])
                  }
                >{`${this.state.whole_data2[i]["user_name"]}`}</a>
                {this.state.whole_data2[i]["kyc_status"] == "APPROVED" && (
                  <img
                    src={require("../assets/images/completed.png")}
                    width={"30px"}
                    height={"30px"}
                  />
                )}
                <p>
                  <i
                    style={{ color: this.state.whole_data2[i]["color"] }}
                    className=" mr5"
                  />
                  {`${this.state.whole_data2[i]["userStatus"]}`}{" "}
                  <span>
                    <a
                      href={`/feedback/${base32.encode(
                        this.state.whole_data2[i]["user_id"]
                      )}`}
                    >
                      {" "}
                      (+
                      {`${this.state.whole_data2[i]["posi"]}`}
                      {" / "}
                      {" -"}
                      {`${this.state.whole_data2[i]["nega"]}`})
                    </a>
                  </span>
                </p>
              </div>
            </td>
          );
        } else if (j == 1) {
          children.push(
            <td key={j}>
              <a className="anchor-styling">{`${
                this.state.whole_data2[i]["payment_method"]
              }`}</a>
              <p className="mb-0 tags-pay">
                {this.populateTagSell(i)}
                {/* <span className="badge">Primary</span>
            <span className="badge">Primary</span> */}
              </p>
            </td>
          );
        } else if (j == 2) {
          if (this.state.whole_data2[i]["price_equation"] !== undefined) {
            var num = Number(this.state.whole_data2[i]["price_equation"]);
            var n = num.toFixed(2);

            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            children.push(
              <td key={j}>{`${n1 +
                "." +
                n2 +
                " " +
                this.state.whole_data2[i]["currency_type"]}`}</td>
            );
          } else children.push(<td key={j}>0</td>);
        } else if (j == 3) {
          children.push(
            <td key={j}>{`${this.state.whole_data2[i]["min_transaction_limit"] +
              "-" +
              this.state.whole_data2[i]["max_transaction_limit"] +
              " " +
              this.state.whole_data2[i]["currency_type"]}`}</td>
          );
        } else if (j == 4) {
          let num = Number(this.state.whole_data2[i]["toPay"]);
          let num1 = num.toFixed(2);
          this.state.whole_data2[i]["toPay"]
            ? children.push(
                <td key={j} style={{ width: "100px" }}>
                  {"$" + `${num1}`}
                </td>
              )
            : children.push(
                <td key={j} style={{ width: "100px" }}>
                  $0
                </td>
              );
        } else if (j === 5) {
          // console.log("prod===",this.state.whole_data2[i]["product_images"])
          if (this.state.whole_data2[i].hasOwnProperty("product_images")) {
            if (this.state.whole_data2[i]["product_images"].length) {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img
                    src={
                      this.state.whole_data2[i]["product_images"][0]["imageUrl"]
                    }
                    onClick={(e) =>
                      this.setState({
                        showImage: true,
                        prodImg: this.state.whole_data2[i]["product_images"],
                      })
                    }
                  />
                  <span className="imagecount">
                    {this.state.whole_data2[i]["product_images"].length}
                  </span>
                </td>
              );
            } else {
              children.push(
                <td key={j} className="Width110 text-center pRelative">
                  <img src={require("../assets/newImages/imgAlbum.png")} />
                  <span className="imagecount">0</span>
                </td>
              );
            }
          } else {
            children.push(
              <td key={j} className="Width110 text-center pRelative">
                <img src={require("../assets/newImages/imgAlbum.png")} />
                <span className="imagecount">0</span>
              </td>
            );
          }
        } else if (j === 6) {
          children.push(
            <td key={j} className="Width110 text-center">
              <a
                id={i}
                onClick={(e) =>
                  this.SellSpecific(
                    e,
                    this.state.whole_data2[i]["payment_method"]
                  )
                }
                className="btn btnGray minhyt minWidth80"
              >
                Sell
              </a>
            </td>
          );
        }
      }
      //Create the parent and add the children
      table.push(<tr key={i}>{children}</tr>);
    }
    return table;
  };

  // start by Sanjay
  _loadData() {
    //this.setState({ loading: true });
    var parameter = {
      type: "RUNNING_AD",
    };
    try {
      Api("static/staticContent", "POST", parameter).then((resp) => {
        this.setState({ loading: false });
        if (resp.responseCode == 200) {
          this.setState({ _data: resp.data.succ.description });
        } else {
          // toast_func("error", "Unable to reach server please contact to administrator")
        }
      });
    } catch (ex) {
      toast_func(
        "error",
        "Unable to reach server please contact administrator"
      );
    }
  }

  // End by Sanjay
  managetab(path) {
    this.props.history.push(window.open(`/${path}`, "_blank"));
  }

  setValue(value) {
    console.log("value==>", value);
    this.setState({ currency: value, currencyModal: false });
    console.log("============currency==>", this.state.currency);
  }

  setCalValue(value) {
    console.log("calvalue==>", value);
    this.setState({ calCurrency: value, calCurrencyModal: false }, () =>
      this.fetchAmount()
    );
    // console.log("============currency==>",this.state.calCurrency)
  }

  render() {
    var setting = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 4,
    };
    var isloggedIn = localStorage.getItem("isLoggedin");

    const settings = {
      dots: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      // arrows: true,
      infinite: true,
      speed: 1000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div
        className="wrapper"
        style={{ backgroundColor: "var(--white-color)" }}
      >
        <Header
          {...this.props}
          reload={true}
          parentCallback1={this.handleCallback}
        />
        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 200, width: 200 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}
        <ToastContainer />
        {/* <section className="runstrip2">
          <marquee>
            <p>
              Latest Features- Earn Consistent Referral Income and Merchant
              Invoicing for Bitcoin Payments.
            </p>  
          </marquee>
        </section> */}
        <section className="mechantBnr" style={{ marginTop: "110px" }}>
          <div className="container1440">
            <div className="row rowSpace4">
              <div className="col-md-6">
                <div className="tagLine1">
                  <label className="">
                    A Web 3.0 unified P2P Marketplace for buying/selling
                    Bitcoins with 500+ payments, 385+ altcoins, products and
                    services.
                  </label>
                  <button
                    onClick={() => {
                      if (this.state._id) {
                        this.props.history.push(`referFriend`);
                      } else {
                        this.props.history.push(`referral`);
                      }
                    }}
                    className="btn btnGreen pt10 pb10 mb10 minWidth120"
                  >
                    <b>Refer & Earn</b>
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="home1-img">
                  <img
                    src={require("../assets/images/bitcoin-sign.gif")}
                    alt=""
                  />
                </div>
              </div>

              <div className="homecards">
                <div
                  className="card_11 gift"
                  onClick={() => {
                    this.managetab("giftcard");
                  }}
                >
                  <div className="boxMar">
                    <img
                      className="img1"
                      src={require("../assets/newImages/giftCardB.png")}
                    />
                    <img
                      className="img2"
                      src={require("../assets/newImages/giftCardW.png")}
                    />
                  </div>
                  <p className="mt15">Gift Card</p>
                </div>
                <div
                  className="card_11 bnkTrnsfr"
                  onClick={() => {
                    this.managetab("banktransfer");
                  }}
                >
                  <div className="boxMar">
                    <img
                      className="img3"
                      src={require("../assets/newImages/bankTransB.png")}
                    />
                    <img
                      className="img4"
                      src={require("../assets/newImages/bankTransW.png")}
                    />
                  </div>
                  <p className="mt15">Bank Transfers</p>
                </div>
                <div
                  className="card_11 coins"
                  onClick={() => {
                    this.managetab("altcoin");
                  }}
                >
                  <div className="boxMar">
                    <img
                      className="img5"
                      src={require("../assets/newImages/coinsB.png")}
                    />
                    <img
                      className="img6"
                      src={require("../assets/newImages/coinsW.png")}
                    />
                  </div>
                  <p className="mt15">ALT Coins</p>
                </div>
                <div
                  className="card_11 deposits"
                  onClick={() => {
                    this.managetab("cashdeposit");
                  }}
                >
                  <div className="boxMar">
                    <img
                      className="img7"
                      src={require("../assets/newImages/depositsB.png")}
                    />
                    <img
                      className="img8"
                      src={require("../assets/newImages/depositsW.png")}
                    />
                  </div>
                  <p className="mt15">Cash Deposits</p>
                </div>
                <div
                  className="card_11 services"
                  onClick={() => {
                    this.managetab("goods&services");
                  }}
                >
                  <div className="boxMar">
                    <img
                      className="img9"
                      src={require("../assets/newImages/boxesB.png")}
                    />
                    <img
                      className="img10"
                      src={require("../assets/newImages/boxesW.png")}
                    />
                  </div>
                  <p className="mt15">Goods & Services</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          isOpen={this.state.paymethods}
          fade={false}
          style={{
            alignSelf: "center",
            justifyContent: "center",
            margintop: "10%",
            maxWidth: "950px",
            margintop: "60px",
          }}
        >
          <div className="cardBox max-WT-950 center-box">
            <div className="cardHead center-box pb10 text-center posRel">
              <span
                className="crossBtn"
                onClick={() => this.setState({ paymethods: false })}
              >
                <i className="fa fa-times" />
              </span>

              <h4 className="font18 mt0 mb5 text-center">Method</h4>
            </div>
            {/* <p className="rcvCoin pl15 pr15">if you setup 2 step verification,
   you can use the google authenticator app to receive codes even if you don't have an internet connection or mobile service for trades & transactions</p> */}
            <form
              className="mb0"
              style={{
                height: "350px",
                overflowY: "auto",
                backgroundColor: "var(--white-color)",
              }}
            >
              <div className="formBox  max-WT-750 center-box">
                {/* <div className="form-group">
        <label className="control-label">Authenticate code</label>
        <input type="text" className="form-control" name="auth_code" id="auth_cade" maxLength="6" />
      </div> */}
                <div className="form-group mt20">
                  <div className="input-per2">
                    <input
                      type="text"
                      maxLength="15"
                      className="form-control modalSearch"
                      placeholder="All Online offers"
                      name="bank"
                      value={this.state.bank}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                    <span onClick={(e) => this.searchFilter(null, null, e, 'search')}>
                      <i className="fa fa-search" />
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    {this.state.paymentMethodS.map((item, index) => {
                      return (
                        <div className="col-md-4 filterhovereffect" key={index}>
                          <button
                            className="paymethod"
                            title={item.name}
                            onClick={(e) => this.setMethod(e, item)}
                          >
                            <img
                              src={require("../assets/newImages/bitIcon.png")}
                              className=""
                            />
                            {item.name.length < 15
                              ? item.name
                              : item.name.slice(0, 14) + ".."}
                          </button>
                        </div>
                      );
                    })}

                    {/* <div className="col-md-4"> <button className="paymethod"><i className="fa fa-home"></i>Paytm Online Wallet</button></div>
          <div className="col-md-4"> <button className="paymethod"><i className="fa fa-home"></i> Google Pay</button></div> */}
                  </div>
                  {/* <div className="row mt10">
          <div className="col-md-4">
            <button className="paymethod"><i className="fa fa-home"></i>Game Items</button>
          </div>
          <div className="col-md-4"> <button className="paymethod"><i className="fa fa-home"></i>PhonePe</button></div>
          <div className="col-md-4"> <button className="paymetlhod"><i className="fa fa-home"></i> Paypal</button></div>
        </div> */}
                </div>

                {/* <div className='text-center'>
      <button type="submit" className="btn btnGreen w-100 radius4 mb20">Verify</button>
    </div> */}
              </div>
            </form>
          </div>
        </Modal>

        {/* ------------------------------select currency modal----------------------- */}

        <Modal
          isOpen={this.state.currencyModal}
          fade={false}
          style={{
            alignSelf: "center",
            justifyContent: "center",
            margintop: "10%",
            maxWidth: "950px",
            margintop: "60px",
          }}
        >
          <div className="cardBox max-WT-950 center-box">
            <div className="cardHead center-box pb10 text-center posRel">
              <span
                className="crossBtn"
                onClick={() => this.setState({ currencyModal: false })}
              >
                <i className="fa fa-times" />
              </span>

              <h4 className="font18 mt0 mb5 text-center">Currency</h4>
            </div>
            <CurrencyModal
              currencyJson={currencyJson}
              setValue={this.setValue}
            />
          </div>
        </Modal>

        <Modal
          isOpen={this.state.calCurrencyModal}
          fade={false}
          style={{
            alignSelf: "center",
            justifyContent: "center",
            margintop: "10%",
            maxWidth: "950px",
            margintop: "60px",
          }}
        >
          <div className="cardBox max-WT-950 center-box">
            <div className="cardHead center-box pb10 text-center posRel">
              <span
                className="crossBtn"
                onClick={() => this.setState({ calCurrencyModal: false })}
              >
                <i className="fa fa-times" />
              </span>

              <h4 className="font18 mt0 mb5 text-center">Currency</h4>
            </div>
            <CurrencyModal
              currencyJson={currencyJson}
              setValue={this.setCalValue}
            />
          </div>
        </Modal>
        {/* <section className="runstrip2">
        <marquee>
        <p>Get free BTC on your first successful trade- send your username and coinbaazar wallet to help@coinbaazar.com</p>
        </marquee>
        </section> */}
        {/* <section>
        <div className="container1440">
          <div className="par">
            <div className="card_11 gift">
             <div className="boxMar">
               <img className="img1" src={require("../assets/newImages/giftCardB.png")}></img>
               <img className="img2" src={require("../assets/newImages/giftCardW.png")}></img>
            </div>
            <p className="mt15">Gift Card</p>

            
           </div>
           <div className="card_11 bnkTrnsfr">
           <div className="boxMar">
             <img className="img3" src={require("../assets/newImages/bankTransB.png")}></img>
             <img className="img4" src={require("../assets/newImages/bankTransW.png")}></img>
           </div>
             <p className="mt15">Bank Transfers</p>
           </div>           
           <div className="card_11 coins">
           <div className="boxMar">
             <img className="img5" src={require("../assets/newImages/coinsB.png")}></img>
             <img className="img6" src={require("../assets/newImages/coinsW.png")}></img>
             </div>
             <p className="mt15">ALT Coins</p>
           </div>
           <div className="card_11 deposits">
           <div className="boxMar">
             <img className="img7" src={require("../assets/newImages/depositsB.png")}></img>
           <img className="img8" src={require("../assets/newImages/depositsW.png")}></img>
           </div>
             <p className="mt15">Cash Deposits</p>
           </div>  
           <div className="card_11 services">
           <div className="boxMar">
             <img className="img9" src={require("../assets/newImages/boxesB.png")}></img>
           <img className="img10" src={require("../assets/newImages/boxesW.png")}></img>
           </div>
             <p className="mt15">Goods & Services</p>
           </div> 

                          
            </div> 
        </div>
        </section> */}

        <section className="banner2" id="buyBitcoin">
          <div className="container1440">
            <div className="tableBox buySellBox2">
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%", marginBottom: 30 }}>
                  <div className="tableFilter11">
                    <form className="main-form">
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="filterInlineField"
                          // style={{ width: "50%" }}
                        >
                          <div
                            className="input-group"
                            style={{
                              flexWrap: "nowrap",
                            }}
                          >
                            <input
                              type="phone"
                              style={{ minWidth: 60 }}
                              className="form-control radius-TR0 " //radius-BR0 tablefilterInpRad
                              placeholder="Amount"
                              maxLength={10}
                              name="Amount"
                              value={this.state.Amount}
                              onChange={(e) => this.handleOnChange(e)}
                            />

                            <div style={{ width: 115 }}>
                              <input
                                //  style={{  width:'50%' }}
                                type="text"
                                name="currency"
                                placeholder="currency"
                                readOnly
                                value={this.state.currency}
                                className="form-control select-style1  radius-TL0 radius-BL0 borderLeft0"
                                onClick={() =>
                                  this.setState({ currencyModal: true })
                                }
                              />
                            </div>

                            {/* <select className="form-control select-style1 radius-TL0 radius-BL0 borderLeft0" 
                              onChange={(e) => this.onClick_currency(e)}
                              onClick={() =>  this.setState({ currencyModal: true })}>
                                <option>Select Currency</option>
                                {this.Populate_currency()}
                              </select> */}
                          </div>
                        </div>
                        <div
                          className="filterInlineField"
                          // style={{ width: "50%" }}
                        >
                          <select
                            className="form-control select-style1 radius5" //tablefilterInpRad
                            onChange={(e) => this.onClick_country(e)}
                          >
                            <option>Country</option>
                            {this.Populate_country()}
                          </select>
                        </div>
                        <div
                          className="filterInlineField"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <input
                            type="text"
                            name="bank"
                            placeholder="Payment Method"
                            readOnly
                            value={this.state.bank}
                            className="form-control radius5 select-style1"
                            onClick={() => this.setState({ paymethods: true })}
                          >
                            {/* <option>All Online offers</option> */}
                          </input>

                          {/* <div> */}
                          <button
                            style={{ width: "30%", marginLeft: 10 }}
                            className="btn btnGreen pt10 pb10"
                            onClick={(e) => this.searchFilter(null, null, e, 'search')}
                          >
                            <i className="fa fa-search" />
                          </button>
                        </div>
                        {/* </div> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="tableBoxHead d-flex align-items-center flex-wrap">
                <h4 className="mb15">Buy Bitcoins</h4>
              </div>

              <div className="tableBoxBody">
                {/* <div className="table-responsive tbl">
                  <table className="table tableAlignMiddle globalTable2 minWidth1000 table-bordered">
                    <thead className="tbl_hd">
                      <tr>
                        <th>Buyer</th>
                        <th>Payment Method</th>
                        <th>Price/BTC</th>
                        <th>Limit</th>
                        <th>
                          to Pay <label> on the dollar</label>
                        </th>
                        <th>Product Image</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody className="tbl_bdy" >

                      {/* {this.populate_viewBuyMore()} */}

                {/* {this.state.populate_viewBuyMore
                        ? this.populate_viewBuyMore()
                        : this.populate_viewBuy()} */}
                {/* </tbody>
                  </table>
                </div> */}

                {/* table */}

                {/* <div style={{ marginTop: 10, justifyContent: "center", width: '55%', display: 'flex', alignItems: 'center' }}>
                  {/* {this.state.populate_viewBuyMore ? (
                  <button
                    onClick={() =>
                      this.setState({ populate_viewBuyMore: false })
                    }
                    className="showMore btn ml-auto mb25 minWidth120"
                  >
                    Show Less...
                  </button>
                ) : ( */}
                {/* <button
                    onClick={(e) => this.searchFilter(1, null, e)
                    }
                    className="showMore btn ml-auto mb25 minWidth120"
                  >
                    Show More...
                  </button>

                </div>  */}

                {/* <div className="grayShadow" style={{ width: '100%', display: 'flex' }}>
                  <div style={{ height: "10px", width: "30%", backgroundColor: "#aa0a16", marginTop: 10 }}>

                  </div>
                  <div style={{ height: "150px", width: "10px", backgroundColor: "#aa0a16", marginTop: 10, transform: 'skew(20deg)', marginLeft: 20 }}>

                  </div>
                  <div style={{ height: "10px", width: "70%", backgroundColor: "#aa0a16", alignSelf: "flex-end", marginLeft: 20 }}>

                  </div>
                  <p>ashish laskar</p>
                </div> */}
                <div className="tableBoxBody">
                  <div className="table-responsive tbl">
                    <table className="table tableAlignMiddle globalTable2 minWidth1000 table-bordered">
                      <thead className="tbl_hd">
                        <tr>
                          <th>Buy from</th>
                          <th>Buy with</th>
                          <th>Price/BTC</th>
                          <th>Limit</th>
                          <th>To Pay on the dollar</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.whole_data1.length ? (
                        this.state.whole_data1.map((item,index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <a
                                      className="underl"
                                      style={{ justifyContent: "flex-start" }}
                                      onClick={(e) =>
                                        this.viewDetail(e, item.user_id)
                                      }
                                    >
                                      {item.user_name}
                                    </a>
                                    {item.kyc_status == "APPROVED" ? (
                                      <img
                                        style={{
                                          width: 30,
                                          height: 30,
                                          marginLeft: 5,
                                        }}
                                        src={require("../assets/images/completed.png")}
                                      />
                                    ) : null}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      fontSize: 15,
                                      margintop: 5,
                                    }}
                                  >
                                    {item.status == "ACTIVE" ? (
                                      <i
                                        style={{
                                          color: item.color,
                                          marginTop: 3,
                                          marginRight: 5,
                                        }}
                                        className="dot fa fa-circle"
                                      />
                                    ) : null}
                                    <span
                                      style={{
                                        fontSize: 14,
                                      }}
                                    >
                                      {item.userStatus}{" "}
                                    </span>
                                  </div>
                                  <a
                                    style={{
                                      fontSize: 14,
                                      alignSelf: "flex-start",
                                    }}
                                    href={`/feedback/${base32.encode(
                                      item.user_id
                                    )}`}
                                  >
                                    {" "}
                                    (+ {item.posi}
                                    /- {item.nega}){" "}
                                  </a>
                                </div>
                              </td>
                              <td
                                style={
                                  {
                                    flexDirection: "row",
                                    display: "flex",
                                    height: "100%",
                                    marginTop: "20px",
                                  }
                                  // backgroundColor:'red'
                                }
                              >
                                {/* product image */}
                                {item.product_images ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    {item.product_images[0] ? (
                                      <img
                                        className="proimg"
                                        src={item.product_images[0].imageUrl}
                                        alt=""
                                        style={{ border: "solid 2px #f39c12" }}
                                        onClick={(e) =>
                                          this.setState({
                                            showImage: true,
                                            prodImg: item.product_images,
                                          })
                                        }
                                      />
                                    ) : (
                                      <img
                                        className="proimg"
                                        src={
                                          require("../assets/newImages/imgAlbum.png") // style={{ width: 90, height: 90 ,objectFit:'contain',margintop:10}}
                                        }
                                        alt=""
                                        onClick={(e) =>
                                          this.setState({
                                            showImage: true,
                                            prodImg: item.product_images,
                                          })
                                        }
                                      />
                                    )}
                                    <div
                                      className="imgcount"
                                      style={{ backgroundColor: "#3e5c85" }}
                                    >
                                      <p
                                        style={{
                                          fontSize: 13,
                                          color: "white",
                                          marginTop: -4,
                                        }}
                                      >
                                        {item.product_images
                                          ? item.product_images.length
                                          : "0"}
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      className="proimg"
                                      src={
                                        require("../assets/newImages/imgAlbum.png") // style={{ width: 90, height: 90,objectFit:'contain' }}
                                      }
                                    />
                                    <div
                                      className="imgcount"
                                      style={{ backgroundColor: "#3e5c85" }}
                                    >
                                      <p
                                        style={{
                                          fontSize: 13,
                                          color: "white",
                                          marginTop: -4,
                                        }}
                                      >
                                        0
                                      </p>
                                    </div>
                                  </div>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginLeft: 3,
                                  }}
                                >
                                  <span
                                    style={{
                                      width: "180px",
                                      color: "var(--text-grey)",
                                    }}
                                  >
                                    {item.payment_method}
                                  </span>
                                  <div
                                    style={{
                                      marginBottom: 5,
                                      width: "200px",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {item.add_tags.map((tag,ind) => {
                                      return (
                                        <p key={ind}
                                          style={{
                                            color: "var(--text-grey)",
                                            fontSize: 12,
                                            marginBottom: 0,
                                          }}
                                        >
                                          {tag}{" "}
                                        </p>
                                      );
                                    })}
                                  </div>
                                </div>
                              </td>
                              <td>
                                {Number(item.price_equation).toFixed(2)}{" "}
                                {item.currency_type}
                              </td>
                              <td>
                                <p style={{ marginBottom: 0, fontSize: 16 }}>
                                  {"min: " + item.min_transaction_limit}{" "}
                                  {item.currency_type}
                                </p>
                                <p style={{ marginBottom: 0, fontSize: 16 }}>
                                  {"max: " + item.max_transaction_limit}{" "}
                                  {item.currency_type}
                                </p>
                              </td>
                              <td>${Number(item.toPay).toFixed(2)}</td>
                              <td style={{flexDirection:'column'}}>
                                <button
                                  className="showMore btn ml-auto mb25 minWidth120"
                                  onClick={(e) =>
                                    this.BuySpecific(
                                      e,
                                      item._id,
                                      item.payment_method
                                    )
                                  }
                                >
                                  {item.type_of_trade_other}
                                </button>
                                {/* <i class="fas fa-eye"/> */}
                                <div style={{flexDirection:'row',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img src={require("../assets/eye.png")} style={{width:25,objectFit:'contain'}} />
                                {item.visitor?(
                                  <p style={{margin:2,marginLeft:4}}>{item.visitor}</p>
                                ):(

                                  <p style={{margin:2}}>0</p>
                                  )}
                                  </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="6" align="center">
                            <p className="font20 rColor text-center">
                              {this.state.noData}
                              {/* Sorry No Offer Found */}
                            </p>
                          </td>
                        </tr>
                      )}
                      </tbody>
                      {/* <tr>
                        <td>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ justifyContent: 'flex-start' }}>Ashish</span>
                            <span>away (+3 / -0)</span>
                          </div>
                        </td>
                        <td style={{ flexDirection: 'row', display: 'flex' }}>

                          <div style={{}}>
                            <img style={{ width: 70, height: 70 }}
                              src={require("../assets/newImages/firstBuyerImg.png")}
                              alt=""
                            />
                          </div>
                          <div style={{ display: 'flex', marginLeft: 20, flexDirection: 'column' }}>
                            <span style={{ justifyContent: 'flex-start' }}>amazon giftcard</span>
                            <span style={{ backgroundColor: '#f8f2f2', fontSize: 16 }}>bank transfer</span>
                          </div>
                        </td>
                        <td>21,815.28 USD</td>
                        <td>50-200 USD</td>
                        <td>$0.50</td>
                        <td>
                          <button className="showMore btn ml-auto mb25 minWidth120"
                          >
                            Buy
                          </button>
                        </td>
                      </tr> */}
                    </table>
                  </div>
                </div>
                <div style={{ display: "grid", marginTop: 15 }}>
                  {this.state.totalpg == this.state.currPg ||
                  !this.state.whole_data1.length ? (
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        width: "100%",
                        fontSize: 20,
                        margintop: 5,
                      }}
                    >
                      No More Data Found
                    </p>
                  ) : (
                    <button
                      style={{ width: "fit-content", margin: "auto" }}
                      disabled={
                        this.state.totalpg == this.state.currPg ||
                        !this.state.whole_data1.length
                      }
                      onClick={(e) => this.searchFilter(1, null, e,'buy')}
                      className="showMore btn ml-auto mb25 minWidth120"
                    >
                      Show More...
                    </button>
                  )}
                </div>
              </div>

              {/* {this.state.whole_data1Total ? (
                <a href="#buyBitcoin" >
                {this.state.populate_viewBuyMore ? (
                  <Pagination
                    className="mt10"
                    defaultCurrent={1}
                    total={this.state.whole_data1Total}
                    onChange={(value) => this.searchFilter(value, null)}
                  />
                  ) : null}
                  </a>
              ) : null} */}
            </div>
          </div>
          <Modal
            isOpen={this.state.showImage}
            fade={false}
            style={{
              alignSelf: "center",
              justifyContent: "center",
              margintop: "10%",
              maxWidth: "680px",
              margintop: "60px",
            }}
          >
            <div className="cardBox max-WT-950 center-box">
              <div className="cardHead center-box pb10 text-center posRel">
                <span
                  className="crossBtn"
                  onClick={() => this.setState({ showImage: false })}
                >
                  <i className="fa fa-times" />
                </span>

                <h4 className="font18 mt0 mb5 text-center">Product Image</h4>
              </div>
              <ImageModal productImage={this.state.prodImg} />
            </div>
          </Modal>
          <div className="container1440">
            <div className="row align-items-center buySellBox2">
              <div className="col-md-6">
                <div className="largeImg">
                  <img
                    src={require("../assets/newImages/firstBuyerImg.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="frstBuyr">
                  {/* <h4 className="header2">Security</h4> */}
                  {/* <p className="parastyle">
                    If you are a first time buyer, We are there to assist you through live chat. You can buy/sell bitcoins with confidence and trust. We ensure the highest level of security i.e. 2FA, Google Authenticator, Mobile Verification and more, coinbaazar is protected with the latest security measures. We employ automatic DDoS protection, maintain daily Backups and most importantly 95% of consumer's funds are stored offline!!
                   </p> */}
                  {/* <a className="proxiNova btn btnGreen pt10 pb10 minWidth150 mt30 blackColor radius25 weightBold">
                      READ MORE <i class="fas fa-user-lock"></i>
                  </a> */}
                  <div className="onCoinbaazar">
                    <ul>
                      <li className="mb10">
                        <label>
                          <i className="fa fa-lock" />
                          <span>Advance Data Encryption</span>
                        </label>

                        <p style={{ color: "var(--text-grey)" }}>
                          Your personal and transaction data is secured via
                          end-to-end encryption.
                          {/* <br></br>To know more<a href="/referral" target="_blank"> Click here</a> */}
                        </p>
                      </li>
                      <li className="mb10">
                        <label>
                          <i className="fas fa-shield-alt" />
                          <span>User Security</span>
                        </label>

                        <p style={{ color: "var(--text-grey)" }}>
                          Integrated with highest level of security such as
                          DDos, 2FA, Google Authenticator, Mobile Verification
                          and more.
                          {/* <br></br>To know more<a href="/security" target="_blank"> Click here</a> */}
                        </p>
                      </li>
                      <li className="mb10">
                        <label>
                          <i className="fab fa-bitcoin" />
                          <span>Funds Security</span>
                        </label>
                        <p style={{ color: "var(--text-grey)" }}>
                          We provide world class funds security, by maintaining
                          hot and cold wallet structures.
                          {/* <br></br>To know more<a href="/merchant" target="_blank"> Click here</a> */}
                        </p>
                      </li>
                      <li className="mb10" id="sellBitcoin">
                        <label>
                          <i className="fa fa-user" />
                          <span>Personalized Access Control</span>
                        </label>
                        <p style={{ color: "var(--text-grey)" }}>
                          Advanced access control allows you to restrict devices
                          and addresses that can access your account, for your
                          peace of mind.
                          {/* <br></br>To know more<a href="/social_media" target="_blank"> Click here</a> */}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container1440">
            <div className="tableBox buySellBox2">
              <div className="tableBoxHead d-flex align-items-center flex-wrap">
                <h4 className="mb15">Sell Bitcoins</h4>
                {/* {this.state.populate_viewSellMore ? (
                  <button
                    onClick={() =>
                      this.setState({ populate_viewSellMore: false })
                    }
                    className="showMore btn ml-auto mb25 minWidth120"
                  >
                    Show Less...
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      this.setState({ populate_viewSellMore: true })
                    }
                    className="showMore btn ml-auto mb25 minWidth120"
                  >
                    Show More...
                  </button>
                )} */}
              </div>
              <div className="tableBoxBody">
                <div className="table-responsive tbl">
                  <table className="table tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                    <thead className="tbl_hd">
                      <tr>
                        <th>Sell to</th>
                        <th>Sell with</th>
                        <th>Price/BTC</th>
                        <th>Limit</th>
                        <th>To Pay on the dollar</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.whole_data2.length ? (
                      this.state.whole_data2.map((item,index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <a
                                    className="underl"
                                    style={{ justifyContent: "flex-start" }}
                                    onClick={(e) =>
                                      this.viewDetail(e, item.user_id)
                                    }
                                  >
                                    {item.user_name}
                                  </a>
                                  {item.kyc_status == "APPROVED" ? (
                                    <img
                                      style={{
                                        width: 30,
                                        height: 30,
                                        marginLeft: 5,
                                      }}
                                      src={require("../assets/images/completed.png")}
                                    />
                                  ) : null}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    fontSize: 15,
                                    margintop: 5,
                                  }}
                                >
                                  {item.status == "ACTIVE" ? (
                                    <i
                                      style={{
                                        color: item.color,
                                        marginTop: 3,
                                        marginRight: 5,
                                      }}
                                      className="dot fa fa-circle"
                                    />
                                  ) : null}
                                  <span style={{ fontSize: 14 }}>
                                    {item.userStatus}{" "}
                                  </span>
                                </div>
                                <a
                                  style={{
                                    fontSize: 14,
                                    alignSelf: "flex-start",
                                  }}
                                  href={`/feedback/${base32.encode(
                                    item.user_id
                                  )}`}
                                >
                                  {" "}
                                  (+ {item.posi}
                                  /- {item.nega}){" "}
                                </a>
                              </div>
                            </td>
                            <td
                              style={{ flexDirection: "row", display: "flex" }}
                            >
                              {/* product image */}
                              {item.product_images ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.product_images[0] ? (
                                    <img
                                      className="proimg"
                                      src={item.product_images[0].imageUrl}
                                      alt=""
                                      style={{ border: "solid 2px #f39c12" }}
                                      onClick={(e) =>
                                        this.setState({
                                          showImage: true,
                                          prodImg: item.product_images,
                                        })
                                      }
                                    />
                                  ) : (
                                    <img
                                      className="proimg"
                                      src={
                                        require("../assets/newImages/imgAlbum.png") // style={{ width: 90, height: 90 ,objectFit:'contain',margintop:10}}
                                      }
                                      alt=""
                                      onClick={(e) =>
                                        this.setState({
                                          showImage: true,
                                          prodImg: item.product_images,
                                        })
                                      }
                                    />
                                  )}
                                  <div
                                    className="imgcount"
                                    style={{ backgroundColor: "#3e5c85" }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 13,
                                        color: "white",
                                        marginTop: -4,
                                      }}
                                    >
                                      {item.product_images
                                        ? item.product_images.length
                                        : "0"}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    className="proimg"
                                    src={
                                      require("../assets/newImages/imgAlbum.png") // style={{ width: 90, height: 90,objectFit:'contain' }}
                                    }
                                  />
                                  <div
                                    className="imgcount"
                                    style={{ backgroundColor: "#3e5c85" }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 13,
                                        color: "white",
                                        marginTop: -4,
                                      }}
                                    >
                                      0
                                    </p>
                                  </div>
                                </div>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span
                                  style={{
                                    width: "180px",
                                    color: "var(--black-color)",
                                  }}
                                >
                                  {item.payment_method}
                                </span>
                                <div
                                  style={{
                                    marginBottom: 5,
                                    width: "200px",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {item.add_tags.map((tag,ind) => {
                                    return (
                                      <p key={ind}
                                        style={{
                                          color: "var(--text-grey)",
                                          fontSize: 12,
                                          marginBottom: 0,
                                        }}
                                      >
                                        {tag}{" "}
                                      </p>
                                    );
                                  })}
                                </div>
                              </div>
                            </td>
                            <td>
                              {Number(item.price_equation).toFixed(2)}{" "}
                              {item.currency_type}
                            </td>
                            <td>
                              <p style={{ marginBottom: 0, fontSize: 16 }}>
                                {"min: " + item.min_transaction_limit}{" "}
                                {item.currency_type}
                              </p>
                              <p style={{ marginBottom: 0, fontSize: 16 }}>
                                {"max: " + item.max_transaction_limit}{" "}
                                {item.currency_type}
                              </p>
                            </td>
                            <td>${Number(item.toPay).toFixed(2)}</td>
                            <td>
                              <button
                                className="showMore btn ml-auto mb25 minWidth120"
                                onClick={(e) =>
                                  this.SellSpecific(
                                    e,
                                    item._id,
                                    item.payment_method
                                  )
                                }
                              >
                                {item.type_of_trade_other}
                              </button>
                              <div style={{flexDirection:'row',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img src={require("../assets/eye.png")} style={{width:25,objectFit:'contain'}} />
                                {item.visitor?(
                                  <p style={{margin:2,marginLeft:4}}>{item.visitor}</p>
                                ):(

                                  <p style={{margin:2}}>0</p>
                                  )}
                                  </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6" align="center">
                          <p className="font20 rColor text-center">
                            {this.state.noData}
                            {/* Sorry No Offer Found */}
                          </p>
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div
                style={{
                  justifyContent: "center",
                  width: "55%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    marginTop: 15,
                  }}
                  disabled={this.state.pgval2<= this.state.pages2}
                  onClick={(e) => this.searchFilter(null, 1, e)}
                  className="showMore btn ml-auto mb25 minWidth120"
                >
                  Show More...
                 {/* {this.state.pages2<= this.state.pgval2 ? 'Show More...' : 'No More Offers...'} */}
              {/* </button> */}
              {/* </div> */}
              <div style={{ display: "grid", marginTop: 15 }}>
                {this.state.totalpg == this.state.currPg ||
                !this.state.whole_data2.length ? (
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 600,
                      width: "100%",
                      fontSize: 20,
                      margintop: 5,
                    }}
                  >
                    No More Data Found
                  </p>
                ) : (
                  <button
                    style={{ width: "fit-content", margin: "auto" }}
                    disabled={
                      this.state.totalpg == this.state.currPg ||
                      !this.state.whole_data2.length
                    }
                    onClick={(e) => this.searchFilter(null, 1, e, 'sell')}
                    className="showMore btn ml-auto mb25 minWidth120"
                  >
                    Show More...
                  </button>
                )}
              </div>
              {/* {this.state.whole_data2Total ? (
                <a href="#sellBitcoin" >
                  {this.state.populate_viewSellMore ? (
                    <Pagination
                      className="mt10"
                      defaultCurrent={1}
                      total={this.state.whole_data2Total}
                      onChange={(value) => this.searchFilter(null, value)}
                    />
                  ) : null}
                </a>
              ) : null} */}
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "var(--white-color)" }}>
          <div className="container1440">
            <div className="row align-items-center buySellBox2">
              <div className="col-md-6">
                <div className="largeImg w80 widthStyle">
                  <h4 className="header2"> On Coinbaazar</h4>
                  {/* <p className="parastyle">
                    Buy and Sell cryptocurrencies in a fast and secure way by 
                    paying low fee. CoinBaazar ensures you smooth transactions 
                    between buyer and seller online via live chat .We are committed 
                    to provide you quick support for any instance.You can use 
                    coinbaazar to store, send and receive bitcoins with an ease of access to your wallets.</p> 
                    */}
                  <div className="onCoinbaazar">
                    <ul>
                      <li>
                        <label>
                          <i className="fa fa-users" />
                          <span>Earn consistent income through referrals</span>
                        </label>

                        <p style={{ color: "var(--text-grey)" }}>
                          Coinbaazar referral program lets you earn bitcoins on
                          every successful trade by your referrals.
                          <br />
                          To know more
                          <a href="/referral" target="_blank">
                            {" "}
                            Click here
                          </a>
                        </p>
                      </li>
                      <li>
                        <label>
                          <i className="fas fa-shield-alt" />
                          <span>Protected by Escrow</span>
                        </label>

                        <p style={{ color: "var(--text-grey)" }}>
                          All trades are protected by secured escrow system,
                          which ensures the safety of both buyer and seller.
                          <br />
                          To know more
                          <a href="/security" target="_blank">
                            {" "}
                            Click here
                          </a>
                        </p>
                      </li>
                      <li>
                        <label>
                          <i className="fab fa-bitcoin" />
                          <span>Become a Merchant</span>
                        </label>
                        <p style={{ color: "var(--text-grey)" }}>
                          Create invoice and share it to your customers, start
                          accepting payments in bitcoins for goods and services.
                          <br />
                          To know more
                          <a href="/merchant" target="_blank">
                            {" "}
                            Click here
                          </a>
                        </p>
                      </li>
                      <li>
                        <label>
                          <i className="fa fa-comments" />
                          <span>Connecting Crypto Peers</span>
                        </label>
                        <p style={{ color: "var(--text-grey)" }}>
                          A dedicated chat room for all users to communicate
                          with one another, meet other buyer and sellers to grow
                          your business.
                          <br />
                          To know more
                          <a href="/social_media" target="_blank">
                            {" "}
                            Click here
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="w80 largeImg">
                  <img
                    src={require("../assets/newImages/onCoinbaazarYouCan.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="banner3">
          <div className="container1440 ">
            <div className="text-center bcal">
              <h2 className="font18 textInitial fontfamTB">
                Bitcoin Price Calculator
              </h2>
            </div>
            <div className="txtBox">
              <div className="row margin0">
                <div className="formBox max-WT-400 center-box">
                  <label>Amount in Bitcoin</label>
                  <div className="input-group w100Per">
                    <input
                      type="number"
                      maxLength={35}
                      className="form-control minHeight45 calCurr"
                      placeholder="Enter Amount"
                      name="BTCAmount"
                      value={this.state.BTCAmount}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" id="basic-addon2">
                        BTC
                      </span>
                    </div>
                  </div>
                </div>
                <span className="equalto">=</span>
                <div className="formBox max-WT-400 center-box">
                  <label>Amount in Selected Currency</label>
                  <div className="input-group">
                    <input
                      type="number"
                      maxLength={35}
                      className="form-control minHeight45 calCurr"
                      placeholder="Enter Amount"
                      name="calAmount"
                      value={this.state.calAmount}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                    <div className="input-group-append Width80">
                      {/* <select className="form-control select-style1 radius-TL0 radius-BL0 borderLeft0 minHeight45 selCurr" name="calCurrency"   onChange={(e) => this.handleOnChange(e)}>
															<option>{this.state.calCurrency}</option>
															{this.Populate_currency()}
														</select> */}
                      <input
                        type="text"
                        name="currency"
                        placeholder="Select Currency"
                        readOnly
                        value={this.state.calCurrency}
                        className="form-control select-style1 radius-TL0 radius-BL0 borderLeft0"
                        onClick={() =>
                          this.setState({ calCurrencyModal: true })
                        }
                      />
                    </div>
                    {/* <button className="btn btnGreen radius4 ml10"><i className="fas fa-chart-bar"></i> </button> */}
                  </div>
                </div>
              </div>
              <div className="row graphBox">
                {/* <img src="https://cdn.statcdn.com/Statistic/805000/807195-blank-754.png" title="Iframe Example" /> */}
                {/* <TradeViewChart
                containerStyle={{
                  minHeight: '300px',
                  minWidth: '400px',
                  marginBottom: '30px',
                }}
                chartLayout={{
                  layout: {
                      backgroundColor: "#fff",
                      textColor: "#253248",
                  },
                  grid: {
                      vertLines: {
                      color: "#838fa3",
                      // style: LineStyle.SparseDotted,
                      },
                      horzLines: {
                      color: "#838fa3",
                      // style: LineStyle.SparseDotted,
                      },
                  },
                  crosshair: {
                      // mode: CrosshairMode.Normal,
                  },
                  priceScale: {
                      borderColor: "#485c7b",
                  },
                  timeScale: {
                      borderColor: "#485c7b",
                      timeVisible: true,
                      secondsVisible: false,
                  },
              }}
           
                pair="BTCUSDT"
              /> */}
                {/* <iframe
                  src="https://widget.coinlib.io/widget?type=chart&theme=light&coin_id=859&pref_coin_id=1505"
                  title="Coinbaazar exchange"
                  style={{ width: "100%", height: "350px" }}
                /> */}
                <iframe src="https://s.tradingview.com/widgetembed/?frameElementId=tradingview_89cd8&symbol=BITSTAMP%3ABTCUSD&interval=1&hidesidetoolbar=1&symboledit=1&saveimage=1&toolbarbg=f1f3f6&studies=[]&theme=Light&style=1&timezone=Etc%2FUTC&studies_overrides={}&overrides={}&enabled_features=[]&disabled_features=[]&locale=en&utm_source=yourwebsite.com&utm_medium=widget&utm_campaign=chart&utm_term=BITSTAMP%3ABTCUSD" frameborder="0" allowtransparency="true" scrolling="no"
                title="Coinbaazar exchange"
                style={{ width: "100%", height: "350px" }}/>

              </div>
            </div>
          </div>
          <div className="container1440 mt50">
            <div className="">
              <div className="sectionHead text-center">
                <h4 className="header2">Latest News and Events</h4>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="newsCardBox">
                    <figure>
                      <img src={this.state.dataRssB[0]} alt="" />
                    </figure>
                    <div className="newsContent height135">
                      <h4>Latest News and Events</h4>
                      <p>
                        {this.state.dataRssA.length != 0
                          ? this.state.dataRssA[0].title
                          : ""}
                      </p>
                    </div>
                    <div className="newsContent">
                      <a
                        href={
                          this.state.dataRssA.length != 0
                            ? this.state.dataRssA[0].link
                            : "#"
                        }
                        target="_blank"
                        className="readMore"
                      >
                        <span>Read More</span>
                        <img
                          src={require("../assets/images/right-arrow.png")}
                          alt="icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="newsCardBox">
                    <figure>
                      <img src={this.state.dataRssB[1]} alt="" />
                    </figure>
                    <div className="newsContent height135">
                      <h4>Latest News and Events</h4>
                      <p>
                        {this.state.dataRssA.length != 0
                          ? this.state.dataRssA[1].title
                          : ""}
                      </p>
                    </div>
                    <div className="newsContent">
                      <a
                        href={
                          this.state.dataRssA.length != 0
                            ? this.state.dataRssA[1].link
                            : "#"
                        }
                        target="_blank"
                        className="readMore"
                      >
                        <span>Read More</span>
                        <img
                          src={require("../assets/images/right-arrow.png")}
                          alt="icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="newsCardBox">
                    <figure>
                      <img src={this.state.dataRssB[2]} alt="" />
                    </figure>
                    <div className="newsContent height135">
                      <h4>Latest News and Events</h4>
                      <p>
                        {this.state.dataRssA.length != 0
                          ? this.state.dataRssA[2].title
                          : ""}
                      </p>
                    </div>
                    <div className="newsContent">
                      <a
                        href={
                          this.state.dataRssA.length != 0
                            ? this.state.dataRssA[2].link
                            : "#"
                        }
                        target="_blank"
                        className="readMore"
                      >
                        <span>Read More</span>
                        <img
                          src={require("../assets/images/right-arrow.png")}
                          alt="icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="newsCardBox">
                    <figure>
                      <img src={this.state.dataRssB[3]} alt="" />
                    </figure>
                    <div className="newsContent height135">
                      <h4>Latest News and Events</h4>
                      <p>
                        {this.state.dataRssA.length != 0
                          ? this.state.dataRssA[3].title
                          : ""}
                      </p>
                    </div>
                    <div className="newsContent">
                      {" "}
                      <a
                        href={
                          this.state.dataRssA.length != 0
                            ? this.state.dataRssA[3].link
                            : "#"
                        }
                        target="_blank"
                        className="readMore"
                      >
                        <span>Read More</span>
                        <img
                          src={require("../assets/images/right-arrow.png")}
                          alt="icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section>
      <div className="container1440">
         <div className="buySellBox2">
              <div className="sectionHead text-center">
                <h4 className="header2">Coinbaazar Featured On</h4>
              </div>
              <div className="row">
             
                <div className="col-lg-3 col-md-6 newsTopSpcae mar_auto ">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/voyagelogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae mar_auto">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/yahoologo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae mar_auto">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/zycryptologo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae mar_auto">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/yahoofinancelogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
               
                </div>
                <div className="row">
                <div className="col-lg-3 col-md-6 newsTopSpcae mar_auto">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/ethereumlogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae mar_auto">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/coinsmartlogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae mar_auto">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/coinparelogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae mar_auto">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/coinscribblelogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                </div>
                
                <div className="row">
                <div className="col-lg-3 col-md-6 newsTopSpcae mar_auto">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/newsbreaklogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae mar_auto">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/edtimeslogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae mar_auto">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/bitlogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae mar_auto">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/cryptonews.jpg")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
               </div>
                </div>
                </div>
                
      </section> */}
        <section className="newsSection commonPad">
          <div className="container1440">
            <div className="sectionHead text-center">
              <h4 className="header2">Coinbaazar Featured On</h4>
            </div>
            <marquee>
              <div className="featuredContent">
                <div className="col-sm-2 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img
                        src={require("../assets/images/voyagelogo.png")}
                        alt="Voyage Times"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-sm-2 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img
                        src={require("../assets/images/yahoologo.png")}
                        alt="Voyage Times"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-sm-2 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img
                        src={require("../assets/images/zycryptologo.png")}
                        alt="Voyage Times"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-sm-2 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img
                        src={require("../assets/images/yahoofinancelogo.png")}
                        alt="Voyage Times"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-sm-2 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img
                        src={require("../assets/images/ethereumlogo.png")}
                        alt="Voyage Times"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-sm-2 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img
                        src={require("../assets/images/coinsmartlogo.png")}
                        alt="Voyage Times"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-sm-2 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img
                        src={require("../assets/images/coinparelogo.png")}
                        alt="Voyage Times"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-sm-2 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img
                        src={require("../assets/images/coinscribblelogo.png")}
                        alt="Voyage Times"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-sm-2 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img
                        src={require("../assets/images/newsbreaklogo.png")}
                        alt="Voyage Times"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-sm-2 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img
                        src={require("../assets/images/edtimeslogo.png")}
                        alt="Voyage Times"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-sm-2 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img
                        src={require("../assets/images/bitlogo.png")}
                        alt="Voyage Times"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-sm-2 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img
                        src={require("../assets/images/cryptonews.jpg")}
                        alt="Voyage Times"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </marquee>
          </div>
        </section>
        {isloggedIn !== "true" ? (
          <section
            className="pt35"
            style={{ backgroundColor: "var(--white-color)" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 mar_auto">
                  <div className="createAccountBox">
                    <p className="">
                      Getting started is easy. Create your account now.
                    </p>
                    <Link to={"/signup"}>
                      <a className="btn btnBlack max-WT-190 wColor p10 pb10" href="">
                        Create an Account Now</a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}

        {/* --------------------new Design end----------- */}
        {/* <section className="banner-home clearfix"> */}

        {/*<div className="sky">
              <div className="stars"></div>
              <div className="stars1"></div>
              <div className="stars2"></div>
              <div className="shooting_stars"></div>
      </div>*/}
        {/* <div className={styles.rocket}>
              <div className={styles.rocket_body}>
                <div className={styles.bodyin}></div>
                <div className={styles.fin_left}></div>
                <div className={styles.fin_right}></div>
                <div className={styles.window}></div>
              </div>
              <div className={styles.exhaust_flame}></div>
              <ul className={styles.exhaust_fumes}>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
      </div> */}

        {/* <div className="topBanner-content d-flex align-items-center w-100">
            <div id="particles-js" className="particles-animation"></div> 
            <div className="container1440 homesection1">

              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="tagLine">
                    <label className="tagLab">
                    A LOW FEE P2P EXCHANGE
                   FASTEST & SIMPLEST WAY TO BUY/SELL BITCOINS
                    </label> */}
        {/* <a className="proxiNova btn btnGreen pt10 pb10 minWidth150 mt30 blackColor radius25 weightBold">
                      READ MORE
                    </a> */}

        {/* <Slider {...settings}>
              
                      <div className="slideimgbox">
                        <div className="slideimg">
                          <img style={{height:176}}
                            src={require("../assets/images/AppImage4.jpeg")}
                            alt="Image"
                          />
                        </div>
                        <div className="slidetext">
                          <h3>GIFTCARDS</h3>
                        </div>
                      </div>
                    
                      <div className="slideimgbox ml10 ">
                        <div className="slideimg">
                          <img style={{height:176}}
                            src={require("../assets/images/AppImage1.jpeg")}
                            alt="Image"
                          />
                        </div>
                        <div className="slidetext">
                          <h3>BANK TRANSFERS</h3>
                        </div>
                      </div>
                      <div className="slideimgbox ml20">
                        <div className="slideimg">
                          <img style={{height: 176}}
                            src={require("../assets/images/AppImage2.jpeg")}
                            alt="Image" />
                        </div>
                        <div className="slidetext">
                          <h3>ALTCOINS</h3>
                        </div>
                      </div>
                      <div className="slideimgbox ml30">
                        <div className="slideimg">
                          <img style={{height: 176}}
                            src={require("../assets/images/AppImage3.jpeg")}
                            alt="Image"
                          />
                        </div>
                        <div className="slidetext">
                          <h3>CASH DEPOSITS</h3>
                        </div>
                      </div>
                    </Slider> */}

        {/*                     
                      
                  </div>
                </div>
                   
              </div>
              </div>

          <div className="container1440 homesection2 fullScr">
          <div className="row">
          <div className="col-sm-3 txtCntr feature1">
            <div className="feature2">
            <img src={require("../assets/images/unnamed (7).png")} className="iconImg" alt=""/>
            </div>
        <div className="textStyle"> <span>GIFT CARDS</span></div>
          </div>
          <div className="col-sm-3 txtCntr feature1">
          <div className="feature2">
          <img src={require("../assets/images/unnamed (8).png")} className="iconImg" alt=""/>
          </div>
          <div className="textStyle"> <span>BANK TRANSFERS</span></div>
          </div>
          <div className="col-sm-3 txtCntr feature1">
          <div className="feature2">
          <img src={require("../assets/images/unnamed (6).png")} className="iconImg" alt=""/>
          </div>
          <div className="textStyle" style={{marginTop:5}}> <span>ALT COINS</span></div>
          </div>
          <div className="col-sm-3 txtCntr feature1">
          <div className="feature2">
          <img src={require("../assets/images/unnamed (5).png")} className="iconImg" alt=""/>
          </div>
          <div className="textStyle" style={{marginTop:5}}> <span>CASH DEPOSITS</span></div>
          </div>
        </div>
            </div> */}
        {/* -------mob-view------------ */}
        {/* <div className="container1440 homesection2 mobView">
            <AwesomeSlider cssModule={AwesomeSliderStyles}>
           <div className="txtCntr feature1">
            <div className="feature2">
            <img src={require("../assets/images/unnamed (7).png")} className="iconImg" alt=""/>
            </div>
            <div className="textStyle"> <span>GIFT CARDS</span></div>
          </div>
          <div className="col-sm-3 txtCntr feature1">
            <div className="feature2">
            <img src={require("../assets/images/unnamed (8).png")} className="iconImg" alt=""/>
            </div>
            <div className="textStyle"> <span>BANK TRANSFERS</span></div>
          </div>
          <div className="col-sm-3 txtCntr feature1">
            <div className="feature2">
            <img src={require("../assets/images/unnamed (6).png")} className="iconImg" alt=""/>
            </div>
            <div className="textStyle" style={{marginTop:5}}> <span>ALT COINS</span></div>
          </div>
          <div className="col-sm-3 txtCntr feature1">
            <div className="feature2">
            <img src={require("../assets/images/unnamed (5).png")} className="iconImg" alt=""/>
            </div>
            <div className="textStyle" style={{marginTop:5}}> <span>CASH DEPOSITS</span></div>
          </div>
  </AwesomeSlider>
            </div>


          </div>
            <marquee>
              <div className="runstrip">
                <h4>Get free BTC on your first successful trade- send your username and coinbaazar wallet to help@coinbaazar.com</h4> */}
        {/* <h4>Due to system upgradation some services may be effected, and transactions may take longer than expected time</h4> */}
        {/* </div>
            </marquee> */}
        {/* sanjay code  */}
        {/* <div>
			<marquee direction="left"> 
			{this.state._data != undefined ?
                                   renderHTML(`${this.state._data}`)
                                    : null}
			
			</marquee>
		  </div>	 */}
        {/* sanjay code end */}

        {/* </section> */}
        {/* <main className="mainContent"> */}
        {/* {this.callView()} */}

        {/* 
          <section className="partnerSection commonSectionStyle pb0 sectMar">
           
              <div className="boxClr">
              <div className="buysll">
                <div className="sectionHead text-center nww">
                  <h2 className="head-underline whiteHdr">Buy/Sell</h2>
                  <p style={{color:"#fff"}}>Buy and Sell Bitcoin from more than 300+ payment methods around the world.</p>
                </div>
              </div>
              <div className="tableFilter">
                <form className="main-form">
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="filterInlineField">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control borderRight0 radius-TR0 radius-BR0"
                          placeholder="Enter Amount"
                          name="Amount"
                          value={this.state.Amount}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        <div className="input-group-append">
                          <select className="form-control select-style1 radius-TL0 radius-BL0 borderLeft0" value={this.state.currency} onChange={(e) => this.onClick_currency(e)}>
                            <option>Select currency</option>
                            {this.Populate_currency()}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="filterInlineField">
                      <select className="form-control select-style1" onChange={(e) => this.onClick_country(e)}>
                        <option>Select Country</option>
                        {this.Populate_country()}
                      </select>
                    </div>
                    <div className="filterInlineField">
                      <select className="form-control select-style1" onChange={(e) => this.onClick_paymentBank(e)}>
                        <option>All Online offers</option>
                        {this.Populate_paymentMethods()}
                      </select>
                    </div>
                    <button className="proxiNova btn btnSearch pt10 pb10 minWidth120" onClick={(e) => this.searchFilter(null, null, e)}>Search
                 </button>
                  </div>
                </form>
              </div>
              </div>
           
              <div className="container1440">
              <div className="tableBox">
                <div className="tableBoxHead d-flex align-items-center flex-wrap">
                  <h4 className="mb15">Buy Bitcoins</h4>
                  {this.state.populate_viewBuyMore ?
                    <button onClick={() => this.setState({ populate_viewBuyMore: false })} className="showMore btn ml-auto mb25 minWidth120">Show Less...</button> :
                    <button onClick={() => this.setState({ populate_viewBuyMore: true })} className="showMore btn ml-auto mb25 minWidth120">Show More...</button>}
                </div>
                <div className="tableBoxBody">
                  <div className="table-responsive tbl">                
                    <table className="table tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                      <thead className="tbl_hd">
                        <tr>
                          <th>Buyer</th>
                          <th>Payment Method</th>
                          <th>Price/BTC</th>
                          <th>Limit</th>
                          <th>to Pay <label> on the dollar</label></th>
                          <th>Action</th>

                        </tr>
                      </thead>
                      <tbody>
                        {this.state.populate_viewBuyMore ?
                          this.populate_viewBuyMore() : this.populate_viewBuy()}
                      </tbody>
                    </table>
                  </div>
                </div>
                {this.state.whole_data1Total ?
                  this.state.populate_viewBuyMore ?
                    <Pagination className="mt10" defaultCurrent={1} total={this.state.whole_data1Total} onChange={(value) => this.searchFilter(value, null)} />
                    : null : null}
              </div>
              <div className="tableBox">
                <div className="tableBoxHead d-flex align-items-center flex-wrap">
                  <h4 className="mb15">Sell Bitcoins</h4>
                  {this.state.populate_viewSellMore ?
                    <button onClick={() => this.setState({ populate_viewSellMore: false })} className="showMore btn ml-auto mb25 minWidth120">Show Less...</button>

                    :
                    <button onClick={() => this.setState({ populate_viewSellMore: true })} className="showMore btn ml-auto mb25 minWidth120">Show More...</button>}    </div>
                <div className="tableBoxBody">
                  <div className="table-responsive tbl">
                    <table className="table tableAlignMiddle globalTable2 minWidth1000 tableonhover table-bordered">
                      <thead className="tbl_hd">
                        <tr>
                          <th>Seller</th>
                          <th>Payment Method</th>
                          <th>Price/BTC</th>
                          <th>Limit</th>
                          <th>to Pay <label> on the dollar</label></th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>

                        {this.state.populate_viewSellMore ?
                          this.populate_viewSellMore() : this.populate_viewSell()}
                      </tbody>
                    </table>
                  </div>
                </div>
                {this.state.whole_data2Total ?
                  this.state.populate_viewSellMore ?
                    <Pagination className="mt10" defaultCurrent={1} total={this.state.whole_data2Total} onChange={(value) => this.searchFilter(null, value)} />
                    : null : null}
              </div>
            </div>
          </section>
         
          <section className="partnerSection commonSectionStyle">
            <div className="container1440">
              <div className="row align-items-center disBlck">
                <div className="col-md-6">
                  <div className="sectionHead mb0"> */}
        {/* <h2>Join Coinbazaar partner program and earn Bitcoins!</h2> */}
        {/* <p className="parastyle">
                    If you are a first time buyer, do not worry!! We are there to assist you through live chat. You can buy/sell bitcoins with confidence and trust. We ensure the highest level of security i.e. 2FA, Google Authenticator, Mobile Verification and more, coinbaazar.com is protected with the latest security measures.
                   </p> */}
        {/* <a className="proxiNova btn btnGreen pt10 pb10 minWidth150 mt30 blackColor radius25 weightBold">
                      READ MORE
                  </a> */}
        {/* </div>
                </div>
                <div className="col-md-6">
                  <div className="largeImg">
                    <img src={require("../assets/images/dummy.jpg")} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
         
          <section className="partnerSection youBg">
            <div className="container1440">
            <div className="row">
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="newsCardBox">
                    <iframe width="100%" height="360" src="https://www.youtube.com/embed/ID1QNBEuKkY" allow="fullscreen"></iframe>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="newsCardBox">
                    <iframe width="100%" height="360" src="https://www.youtube.com/embed/cLJijPMe3XI" allow="fullscreen"></iframe>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="newsCardBox">
                    <iframe width="100%" height="360" src="https://www.youtube.com/embed/zmdNWGQvptw" allow="fullscreen"></iframe>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="newsCardBox">
                    <iframe width="100%" height="360" src="https://www.youtube.com/embed/uq5NmLGpADo" allow="fullscreen"></iframe>
                  </div>
                </div>
                <div className="mor">
                  <button className="newBtn algn"><a className="btn al" href="https://www.youtube.com/channel/UCBEaAh_lIXhvV-DA2vuNKpA" target="_blank">View More</a></button>
                </div>
            </div>
            </div>
          </section>
          <section className="walletAnimateSection circleBeforeAnimate">
            <div className="container1440">
              <div className="row align-items-center disBlck">
                <div className="col-md-6">
                  <div className="animationWalletBox center-box pRelative radius100">
                    <div className="w-100">
                      <div className="sectionHead"> */}
        {/* <h2>head1</h2> */}
        {/* <p style={{width: 265}}>
                          Work from home or office and earn as much you want.
 </p>
                      </div>
                      <div className="sectionHead"> */}
        {/* <h2>head2</h2> */}
        {/* <p style ={{width: 255}}>
                          Buy and Sell Bitcoins from anywhere around the world.
 </p>
                      </div>
                      <div className="sectionHead"> */}
        {/* <h2>head3</h2> */}
        {/* <p style={{width: 245}} >
                          Safe and Secure Escrow, for smooth transactions.
 </p>
                      </div>
                      <div className="sectionHead"> */}
        {/* <h2>head4</h2> */}
        {/* <p style={{width: 245}}>
                          Earn through affiliate programmes. Refer people, and you can increase your income.
 </p>
                      </div>
                      <div className="sectionHead active">
                        <h2>Get a free Wallet</h2>
                        <p className="pStyle">
                          Get a wallet , that is easy to use and store Bitcoins. Our executives are there to help you create one .
 </p>
                      </div>
                      <div className="sectionHead"> */}
        {/* <h2>head6</h2> */}
        {/* <p style={{width: 280}}>
                          Build your reputation by gaining positives to draw more clients.
 </p>
                      </div>
                    </div>
                    <div className="w-100">
                      <span className="circleIcon1 circleIcon">
                        <img
                          className="circleImg1"
                          src={require("../assets/images/circle-icon1.png")}
                          alt=""
                        />
                        <img
                          className="circleImg2"
                          src={require("../assets/images/circle-icon1-active.png")}
                          alt=""
                        />
                      </span>
                      <span className="circleIcon2 circleIcon">
                        <img
                          className="circleImg1"
                          src={require("../assets/images/circle-icon2.png")}
                          alt=""
                        />
                        <img
                          className="circleImg2"
                          src={require("../assets/images/circle-icon2-active.png")}
                          alt=""
                        />
                      </span>
                      <span className="circleIcon3 circleIcon">
                        <img
                          className="circleImg1"
                          src={require("../assets/images/circle-icon3.png")}
                          alt=""
                        />
                        <img
                          className="circleImg2"
                          src={require("../assets/images/circle-icon3-active.png")}
                          alt=""
                        />
                      </span>
                      <span className="circleIcon4 circleIcon">
                        <img
                          className="circleImg1"
                          src={require("../assets/images/circle-icon4.png")}
                          alt=""
                        />
                        <img
                          className="circleImg2"
                          src={require("../assets/images/circle-icon4-active.png")}
                          alt=""
                        />
                      </span>
                      <span className="circleIcon5 circleIcon active">
                        <img
                          className="circleImg1"
                          src={require("../assets/images/circle-icon5.png")}
                          alt=""
                        />
                        <img
                          className="circleImg2"
                          src={require("../assets/images/circle-icon5-active.png")}
                          alt=""
                        />
                      </span>
                      <span className="circleIcon6 circleIcon">
                        <img
                          className="circleImg1"
                          src={require("../assets/images/circle-icon6.png")}
                          alt=""
                        />
                        <img
                          className="circleImg2"
                          src={require("../assets/images/circle-icon6-active.png")}
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="sectionHead max-WT-500">
                    <h2>On Coinbaazar You Can</h2>
                    <p className="parastyle">
                    Buy and Sell cryptocurrencies in a fast and secure way by 
                    paying low fee. CoinBaazar ensures you smooth transactions 
                    between buyer and seller online via live chat .We are committed 
                    to provide you quick support for any instance.You can use 
                    coinbaazar to store, send and receive bitcoins with an ease of access to your wallets.

 </p> */}
        {/* <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry’s
                      standard dummy text ever. Lorem Ipsum is simply dummy text
                      of the printing and typesetting industry.
 </p> */}
        {/* <a className="proxiNova btn btnGreen pt10 pb10 minWidth150 mt30 blackColor radius25 weightBold">
                      READ MORE
 </a> */}
        {/* </div>
                </div>
              </div>
            </div>
          </section>
          <section className="newsSection commonSectionStyle">
            <div className="container1440">
              <div className="sectionHead text-center">
                <h2 className="head-underline">Coinbaazar Featured On</h2>
              </div>
              <marquee>
                <div className="featuredContent">
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/voyagelogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/yahoologo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/zycryptologo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/yahoofinancelogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/ethereumlogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/coinsmartlogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/coinparelogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/coinscribblelogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/newsbreaklogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/edtimeslogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/bitlogo.png")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="featuredLogo">
                    <figure>
                      <img src={require("../assets/images/cryptonews.jpg")} alt="Voyage Times" />
                    </figure>
                  </div>
                </div>
               </div>
              </marquee>
            </div>
          </section>
          <section className="newsSection commonSectionStyle bgColor">
            <div className="container1440">
              <div className="sectionHead text-center">
                <h2 className="head-underline whiteHdr">Latest News and Events</h2> */}
        {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever.
 </p> */}
        {/* </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="newsCardBox">
                    <figure> */}
        {/* <img src={require("../assets/images/news1.png")} alt="" /> */}
        {/* <img src={this.state.dataRssB[0]} alt="" />
                    </figure>
                    <div className="newsContent">
                      <label> */}
        {/* {this.state.pubDate} by <strong>Peter Handscomb</strong> */}
        {/* </label>
                      <h4>Latest News and Events</h4>
                      <p>
                      {this.state.dataRssA.length!=0 ? this.state.dataRssA[0].title :''}
                     </p>
                      <a href= {this.state.dataRssA.length!=0 ? this.state.dataRssA[0].link :'#'} target = '_blank' className="readMore">
                        <span>READ MORE</span>
                        <img
                          src={require("../assets/images/right-arrow.png")}
                          alt="icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="newsCardBox">
                    <figure> */}
        {/* <img src={require("../assets/images/news3.png")} alt="" /> */}
        {/* <img src={this.state.dataRssB[1]} alt="" />
                    </figure>
                    <div className="newsContent">
                      <label> */}
        {/* October10, 2018 by <strong>Peter Handscomb</strong> */}
        {/* </label>
                      <h4>Latest News and Events</h4>
                      <p>
        
                         {this.state.dataRssA.length!=0 ? this.state.dataRssA[1].title :''}
                      </p>
                      <a   href =   {this.state.dataRssA.length!=0 ? this.state.dataRssA[1].link :'#'} target = '_blank'  className="readMore">
                        <span>READ MORE</span>
                        <img
                          src={require("../assets/images/right-arrow.png")}
                          alt="icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="newsCardBox">
                    <figure>
                      <img src={this.state.dataRssB[2]} alt="" />
                    </figure>
                    <div className="newsContent">
                      <label> */}
        {/* October10, 2018 by <strong>Peter Handscomb</strong> */}
        {/* </label>
                      <h4>Latest News and Events</h4>
                      <p>
                      {this.state.dataRssA.length!=0 ? this.state.dataRssA[2].title :''}
                      </p>
                      <a   href =   {this.state.dataRssA.length!=0 ? this.state.dataRssA[2].link :'#'} target = '_blank'  className="readMore">
                        <span>READ MORE</span>
                        <img
                          src={require("../assets/images/right-arrow.png")}
                          alt="icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 newsTopSpcae">
                  <div className="newsCardBox">
                    <figure>
                      <img src={this.state.dataRssB[3]} alt="" />
                    </figure>
                    <div className="newsContent">
                      <label> */}
        {/* October10, 2018 by <strong>Peter Handscomb</strong> */}
        {/* </label>
                      <h4>Latest News and Events</h4>
                      <p>
                      {this.state.dataRssA.length!=0 ? this.state.dataRssA[3].title :''}
                      </p>
                      <a   href =   {this.state.dataRssA.length!=0 ? this.state.dataRssA[3].link :'#'} target = '_blank'  className="readMore">
                        <span>READ MORE</span>
                        <img
                          src={require("../assets/images/right-arrow.png")}
                          alt="icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footerIcon"> */}
        {/* <div className="footerLogo">
                <img src ={require("../assets/images/cloudicon.png")}
                          alt="icon"/>
              </div>
              <div className="footerLogo">
                <img src ={require("../assets/images/cloudicon.png")}
                          alt="icon"/>
              </div>
              <div className="footerLogo">
                <img src ={require("../assets/images/bitcoin.png")}
                          alt="icon"/>
              </div> */}
        {/* </div>
            </div>
           
          </section> */}

        {/* <div>
            
          </div> */}

        {/* {isloggedIn !== "true" ?
            <section className="createAccountSection bgGreen">
              <div className="container-fluid">
                <div className="createAccountBox">
                  <span className="wColor">Getting started is easy. Create your account now.</span>
                  <Link to={"/signup"}>
                    <a className="btn btnBlack max-WT-250 wColor p10 pb10 radius25">CREATE AN ACCOUNT NOW</a>
                  </Link>
                </div>
              </div>
            </section>
            : null} */}
        {/* </main> */}

        <Footer {...this.props} />

        {!this.state.zopimElement && (
          <ReactZenDeskChat appID="d5387da4-a81e-46f5-ae0d-8bba0462fc45" />
        )}
      </div>
    );
  }
}

export default Dashboard;
